@import "../../../variables.scss";

.blockText {
  display: inline;
}

.icon {
  color: $gray;
}

.textConnector {
  display: inline;
  margin-left: 4px;
  margin-right: 4px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

:global(.theme-dark) {
  .icon {
    color: $grayDark;
  }
}
