@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-bottom: 12px;
  color: $gray;
  font-size: 14px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.titleText {
  color: $grayDark;
  align-self: center;
}

.icon {
  margin-right: 2px;
  align-self: center;
}

:global(.theme-dark) {
  .titleText {
    color: $grayDark;
  }

  .icon {
    color: $grayDark;
  }
}
