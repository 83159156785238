@import "../../../variables.scss";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video {
  opacity: 1;
  transition: opacity 0.6s ease;
  object-fit: contain;
}

.isLoading {
  opacity: 0;
}

.spinnerContainer {
  position: absolute;
  opacity: 0;
  transition: opacity 0.25s ease;
}
.active {
  opacity: 1;
}

.progressContainer {
  transform: translateY(0px);
  margin-top: -2px;
}

.progress {
  background-color: $gray;
  height: 2px;
  opacity: 0.8;
}
