.container {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  cursor: pointer;
}

.container:focus {
  outline: none;
}
