@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -12px;
}

.textContainer {
  align-self: center;
  margin-right: 16px;
}

.buttonContainer {
  height: fit-content;
  align-self: center;
  flex-shrink: 0;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  border-style: solid;
  border-color: $secondary;
  border-width: 2px;
  border-radius: 6px;
  color: #363636;
}

.questButton {
  display: flex;
  flex-direction: row;
  align-items: center;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.buttonText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonText span {
  flex-shrink: 0;
}

.icon {
  color: $secondary;
  margin-left: 6px;
  font-size: 20px;
  -webkit-transition: color 0.1s;
  -moz-transition: color 0.1s;
  -ms-transition: color 0.1s;
  -o-transition: color 0.1s;
  transition: color 0.1s;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4px;
}

:global(.theme-dark) {
  .buttonContainer {
    color: $dark-foreground;
  }
}

@media screen and (max-width: $max-width-mobile) {
  .container {
    flex-direction: column;
  }

  .textContainer {
    margin-left: 12px;
    margin-right: 12px;
    text-align: center;
  }

  .buttonContainer {
    margin-top: 20px;
  }
}
