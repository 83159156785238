.container {
  padding: 4px;
}

.blockHeader {
  margin-bottom: 4px;
}

.blockHeaderWithTitle {
  margin-bottom: 16px;
}
