.container {
  display: flex;
  justify-content: center;
}

.container span {
  animation: scaleIn 0.8s ease-in-out;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.4);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
