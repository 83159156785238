@import "../../../../variables.scss";

.container hr {
  margin-top: 0px;
}

.container {
  display: flex;
  flex-direction: column;
}

.searchContainer {
  min-height: 285px;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  flex-grow: 1;
}

.inputContainer:not(:last-child) {
  margin-bottom: 4px;
}

.showDescriptionButton {
  flex-grow: 1;
}

.buttonIcon {
  color: $gray;
}

@media only screen and (min-width: 769px) {
  .descriptionContainer {
    flex-direction: row;
  }
  .inputContainer:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 0px;
  }
}
