@import "../../../variables.scss";

.imageContainer {
  height: 128px;
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0px;
  overflow: hidden;
  flex-shrink: 0;

  background: whitesmoke;
  -webkit-transition: height 0.2s, width 0.2s, background 0.2s;
  -moz-transition: height 0.2s, width 0.2s, background 0.2s;
  -ms-transition: height 0.2s, width 0.2s, background 0.2s;
  -o-transition: height 0.2s, width 0.2s, background 0.2s;
  transition: height 0.2s, width 0.2s, background 0.2s;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

:global(.theme-dark) .imageContainer {
  background: rgba(255, 255, 255, 0.1);
}

.imageContainer .questContainer {
  position: absolute;
  right: 10%;
  top: 50%;
  cursor: pointer;
}

.imageContainer .questContainer div {
  transform: translateY(-50%);
  z-index: 1000;
}

.imageContainer .selectionOverlay {
  position: absolute;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 0;
  cursor: pointer;
  background: rgba(235, 235, 235, 0.9);
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

@media (hover: hover) {
  .imageContainer:hover .selectionOverlay {
    opacity: 0.6;
  }
}

.imageContainer .emptyImageIcon {
  color: $gray;
  z-index: 500;
}

.imageContainer .emptyImageIconClickable {
  cursor: pointer;
}

.imageContainer.onDrop {
  outline: 6px solid $third;
  outline-offset: -6px;
}

.imageContainer.onDrop.small {
  outline: 3px solid $third;
  outline-offset: -3px;
}

:global(.theme-dark) {
  .imageContainer {
    background-color: $dark-elevated-9;
  }

  .imageContainer .selectionOverlay {
    background: rgba(255, 255, 255, 0.1);
  }
  .imageContainer .emptyImageIcon {
    color: $grayDark;
  }
}
