.resultsContainer {
  padding-top: 15px;
  padding-left: 1px;
  padding-right: 1px;

  max-height: 240px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.searchContainer {
  margin-bottom: 5px;
}

.noResults {
  color: rgb(181, 181, 181);
  align-self: center;
}
