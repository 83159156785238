#container {
  display: flex;
  align-items: center;
}

#typeText {
  display: inline-block;
  font-size: 12px;
}

.gray {
  color: #b5b5b5;
}
