.container {
  align-self: center;
  display: flex;
  flex-direction: row;
}

.optionContainer {
  display: flex;
  flex-direction: row;
}

.optionContainer:not(:last-child) {
  margin-right: 20px;
}
