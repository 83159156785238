.container {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
}

.container:focus,
.container:hover {
  outline: none;
  background: whitesmoke;
}

.name {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.3);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  flex-grow: 1;
}

.userCountContainer {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  color: rgba(0, 0, 0, 0.3);
  margin-right: 20px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.userCount {
  font-size: 20px;
}

.usersIcon {
  align-self: center;
  margin-left: 2px;
}

mark {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.5);
}

:global(.theme-dark) {
  .container:focus,
  .container:hover {
    outline: none;
    background: rgba(255, 255, 255, 0.08);
  }

  .name {
    color: rgba(255, 255, 255, 0.3);
  }

  mark {
    color: rgba(255, 255, 255, 0.5);
  }
}
