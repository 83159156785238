@import "../../../variables.scss";

.wisdomTreeContainer {
  display: relative;
  flex-flow: column;
  overflow-x: hidden;
  overflow-y: hidden;
}

.loadingSpinner {
  margin-top: 80px;
}

.graphContainer {
  display: flex;
  margin-top: 52px;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgb(240, 240, 240);
  -webkit-transition: height 0.6s $easeInOutQuad;
  -moz-transition: height 0.6s $easeInOutQuad;
  -ms-transition: height 0.6s $easeInOutQuad;
  -o-transition: height 0.6s $easeInOutQuad;
  transition: height 0.6s $easeInOutQuad;
}

.graphContainerEntryBrowser {
  height: $graph_height_default;
}

.graphContainerQuizMode {
  height: $graph_height_quiz;
}

@media only screen and (max-width: 1408px) {
  .graphContainerQuizMode {
    height: $graph_height_quiz_mobile;
  }
}

:global(.theme-dark) {
  .graphContainer {
    background-color: $dark-elevated-0;
  }
}
