@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 120px;
}

.indicatorContainer {
  position: absolute;
  bottom: -20px;
  right: 0px;
}

.userName {
  margin-top: 16px;
  font-size: 1.5em;
  text-align: center;
}

.userTitle {
  margin-top: 0px;
  font-size: 1em;
  text-align: center;
}

.progressContainer {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-left: 10%;
  margin-right: 10%;
}

.expMissingContainer {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 12px;
}

.expIcon {
  color: $primary;
  margin-right: 4px;
}

.textMaxLevel {
  align-self: center;
}

:global(.theme-dark) {
  .expIcon {
    color: $primary_dark;
  }
}
