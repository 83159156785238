.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.title {
  font-size: 24px;
  text-align: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

.subtitle {
  text-align: center;
  margin-top: 0.75rem;
  margin-left: 2rem;
  margin-right: 2rem;
  white-space: pre-wrap;
}

.button {
  margin-top: 2rem;
}

.loadingSpinner {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}
