@import "../../../variables.scss";

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $secondary;
  opacity: 0;
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.15;
  }

  100% {
    opacity: 0;
  }
}
