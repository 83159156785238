@import "../../../../../../variables.scss";

.galleryVertical {
  padding: 0.5vw;
  font-size: 0;
  flex-wrap: wrap;
  flex-direction: row;
  flex-flow: row wrap;
  display: flex;
  margin: 0 auto;
}

.galleryHorizontal {
  flex-wrap: nowrap;
  overflow-x: auto;
  display: flex;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.galleryHorizontal::-webkit-scrollbar {
  display: none;
}

.noResults {
  color: $gray;
}

.searchLoadingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.searchLoadingWrapper p {
  color: $gray;
  margin-bottom: 8px;
}

.previewContainer {
  padding: 12px;
  padding-bottom: 12px;
  margin-top: 2px;
  margin-bottom: 0.75rem;
  margin-left: 12px;
  margin-right: 12px;
}

@media only screen and (max-width: 768px) {
  .previewContainer {
    margin-left: 2px;
    margin-right: 2px;
  }
}

.previewContainer:not(:last-child) {
  margin-bottom: 0.75rem;
}

.previewMoreIcon {
  color: $gray;
  margin-right: 6px;
}

.previewMoreText {
  color: $gray;
  font-weight: bold;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.previewMoreContainer {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: -12px;
  cursor: pointer;
}

.previewMoreContainer:focus {
  outline: none;
}

.previewMoreTileContainer {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  background-color: $grayLight;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  cursor: pointer;
  height: 200px;
  width: 100px;
}

.previewMoreTileContainer:focus {
  outline: none;
}

.previewMoreTileContainer:hover {
  background-color: #e7e7e7;
}

@media screen and (max-width: 768px) {
  .previewMoreTileContainer {
    width: 100px;
    height: 180px;
  }
}

.previewMoreTileText {
  color: #4a4a4a;

  font-size: 14px;
  align-self: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  margin-bottom: auto;
}

.previewMoreTileIcon {
  color: #4a4a4a;
  align-self: center;
  margin-top: auto;
  margin-bottom: 8px;
}

:global(.theme-dark) {
  .previewMoreTileContainer {
    background-color: $dark-elevated-9;
    border-color: rgba(255, 255, 255, 0.2);
  }

  .previewMoreTileContainer:hover {
    background-color: $dark-elevated-11;
  }

  .previewMoreTileText,
  .previewMoreTileIcon {
    color: rgba(255, 255, 255, 0.7);
  }
}
