@import "../../../../variables.scss";

.progressbarOuter {
  background-color: whitesmoke;
  border-radius: 6px;
}

.progressbarInner {
  background-color: $primary;
  height: 20px;
  border-radius: 6px;
}

:global(.theme-dark) {
  .progressbarOuter {
    background-color: rgba(255, 255, 255, 0.08);
  }
  .progressbarInner {
    background-color: $primary_dark;
  }
}
