@import "../../../variables.scss";

.visible {
  visibility: visible;
  opacity: 1;
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

.container .button {
  background-color: #f7f7f7;
  border-color: transparent;
  color: #363636;

  height: 32px;

  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 30px;
  padding-right: 30px;
}

.button:focus {
  border: none;
  border-color: transparent;
}

.button:hover {
  filter: brightness(102%);
  border-color: transparent;
}

.container .icon {
  color: $gray;
  transform: scale(1.4, 1);
}

.container {
  position: absolute;
  left: 50%;
  -webkit-transition: transform 0.6s $easeInOutQuad, visibility 0.3s,
    opacity 0.3s;
  -moz-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  -ms-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  -o-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
}

.defaultPosition {
  transform: translate3d(-50%, calc(#{$graph_height_default} - 40px), 0);
  -webkit-transform: translate3d(
    -50%,
    calc(#{$graph_height_default} - 40px),
    0
  );
}

.quizPosition {
  transform: translate3d(-50%, calc(#{$graph_height_quiz} - 40px), 0);
  -webkit-transform: translate3d(-50%, calc(#{$graph_height_quiz} - 40px), 0);
}

@media only screen and (max-width: 481px) {
  .container .button {
    height: 26px;

    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 24px;
    padding-right: 24px;
  }

  .container .icon {
    color: $gray;
    transform: scale(1.3, 0.9);
  }

  .quizPosition {
    transform: translate3d(-50%, calc(#{$graph_height_quiz_mobile} - 34px), 0);
    -webkit-transform: translate3d(
      -50%,
      calc(#{$graph_height_quiz_mobile} - 34px),
      0
    );
  }

  .defaultPosition {
    transform: translate3d(-50%, calc(#{$graph_height_default} - 34px), 0);
    -webkit-transform: translate3d(
      -50%,
      calc(#{$graph_height_default} - 34px),
      0
    );
  }
}

@media only screen and (max-width: 768px) {
  .quizPosition {
    transform: translate3d(-50%, calc(#{$graph_height_quiz_mobile} - 40px), 0);
    -webkit-transform: translate3d(
      -50%,
      calc(#{$graph_height_quiz_mobile} - 40px),
      0
    );
  }
}

:global(.theme-dark) {
  .container .button {
    background-color: #242424 !important;
    border-color: transparent;
  }

  .container .icon {
    color: #565656 !important;
  }

  .container .button:hover {
    filter: brightness(110%);
    border-color: transparent;
  }
}
