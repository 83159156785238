.cardBody {
  padding: 0px;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 769px), print {
  .modalCard {
    min-width: calc(100vw / 2);
  }
}
