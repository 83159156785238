@import "../../../../variables.scss";

.menuIcon {
  color: $gray;
  height: unset;
}

.dropdownItem {
  display: flex;
  flex-direction: row;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.typeText {
  display: inline-block;
  align-self: center;
  padding-left: 2px;
}

.questContainer {
  margin-left: 2px;
}

.iconActive {
  color: $primary;
}

:global(.theme-dark) {
  .menuIcon {
    color: $grayDark;
  }

  .dropdownItem:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
}
