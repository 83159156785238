@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: row;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: $gray;
  border-radius: 50%;
  opacity: 0.6;
  cursor: pointer;

  -webkit-transition: background-color 0.1s, opacity 0.1s;
  -moz-transition: background-color 0.1s, opacity 0.1s;
  -ms-transition: background-color 0.1s, opacity 0.1s;
  -o-transition: background-color 0.1s, opacity 0.1s;
  transition: background-color 0.1s, opacity 0.1s;
}

.dot.active {
  background-color: $primary;
  opacity: 1;
}

.dot.active.animating {
  background-color: $primary_light;
}

.dot:not(:last-child) {
  margin-right: 8px;
}

:global(.theme-dark) {
  .dot {
    background-color: $grayDark;
  }

  .dot.active {
    background-color: $primary;
  }
}
