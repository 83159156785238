@import "../../../variables.scss";

.visible {
  visibility: visible;
  opacity: 1;
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

.container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 80px;
  width: fit-content;

  -webkit-transition: transform 0.6s $easeInOutQuad, visibility 0.3s,
    opacity 0.3s;
  -moz-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  -ms-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  -o-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
}

.innerContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  border-style: solid;
  border-color: $secondary;
  border-width: 2px;
  color: #363636;

  animation: pulse 2s infinite;

  -webkit-transition: background 0.1s, background-color 0.1s;
  -moz-transition: background 0.1s, background-color 0.1s;
  -ms-transition: background 0.1s, background-color 0.1s;
  -o-transition: background 0.1s, background-color 0.1s;
  transition: background 0.1s, background-color 0.1s;
}

.innerContainer:hover {
  animation: none;
  transform: scale(1.05);
}

.questInfo {
  display: flex;
  flex-direction: row;
  align-items: center;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.questInfoText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.questInfoText span {
  flex-shrink: 0;
}

.icon {
  color: $secondary;
  margin-left: 6px;
  font-size: 20px;
  -webkit-transition: color 0.1s;
  -moz-transition: color 0.1s;
  -ms-transition: color 0.1s;
  -o-transition: color 0.1s;
  transition: color 0.1s;
}

:global(.theme-dark) {
  .innerContainer {
    color: $dark-foreground;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1.05);
  }
}
