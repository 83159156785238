@import "../../../variables.scss";

:global(.tag).container {
  position: relative;
  height: 20px;
  padding: 0px;
  overflow: hidden;
}

.container .text {
  padding-left: 12px;
  padding-right: 12px;
}

.fill {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: $secondary;
  transition: width 1.2s linear;
  overflow: hidden;
}

.container .icon {
  color: white;
  position: absolute;
  height: 24px;
  width: 24px;
  margin: 0px !important;
  left: calc(50% - 12px);
  opacity: 0;
  transition: opacity 0.4s ease 1s;
}

.container .visible {
  opacity: 1;
}

.animateOut {
  animation-name: fadeOut;
  animation-duration: 0.25s;
  animation-delay: 2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 100;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

:global(.theme-dark) {
  :global(.tag).container {
    background-color: #333333;
  }

  .container .text {
    color: $dark-foreground;
  }
}
