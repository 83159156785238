@import "../../../../variables.scss";

.icon {
  align-self: center;
  margin-right: 6px;
  color: #4a4a4a;
}

.container {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  flex-grow: 1;
  align-self: center;
}

.text {
  font-size: 22px;
  align-self: center;
  color: #4a4a4a;
}

:global(.theme-dark) {
  .icon {
    color: $grayDark;
  }
}
