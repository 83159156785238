.container {
  display: flex;
  justify-content: center;
}

.triggerContainer {
  flex-grow: 1;
  padding-top: 24px;
  max-width: 200px;
}

.triggerContainer:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .triggerContainer {
    max-width: none;
  }
}

.buttonContainer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -24px);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.buttonContainer.visible {
  opacity: 1;
  transition-delay: 0.4s;
  pointer-events: all;
}
