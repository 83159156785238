@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.topRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.title {
  font-size: 18px;
  line-height: 1.4;
}

.description {
  line-height: 1.4;
  margin-bottom: 14px;
}

.secondRow {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  margin-left: 68.8px;
}
@media only screen and (max-width: 481px) {
  .secondRow {
    margin-left: 0px;
  }
}

.featureStatusContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
}

.statusColor {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}

.statusText {
  font-size: 11px;
  opacity: 0.7;
}

.authorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
  cursor: pointer;
}

.authorName {
  margin-left: 8px;
}

.timeAgo {
  font-size: 12px;
  opacity: 0.4;
  margin-bottom: 12px;
}
