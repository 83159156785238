@import "../../../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  flex-basis: 33%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  min-height: 240px;
  justify-content: center;

  transition: box-shadow 0.2s;
}

.container:not(:last-child) {
  margin-bottom: 0rem;
  margin-right: 1rem;
}

.container.selected {
  outline: none;
  box-shadow: 0 2px 3px rgba($color: $primary, $alpha: 0.6),
    0 0 0 2px rgba($color: $primary, $alpha: 0.8);
}

.container:focus:not(.selected) {
  outline: none;
  box-shadow: 0 2px 3px rgba($color: $primary, $alpha: 0.6),
    0 0 0 1px rgba($color: $primary, $alpha: 0.6);
}

.iconContainer {
  align-self: center;
  min-width: 30px;
  text-align: center;
  margin-bottom: 6px;
  flex-grow: 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-grow: 0;
  text-align: center;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.icon {
  color: $primary;
  font-size: 34px;
}

.title {
  font-size: 16px;
  margin-bottom: 8px;
}

.subtitle {
  font-size: 14px;
  color: darkgray;
}

:global(.theme-dark) {
  .title {
    color: $dark-foreground;
  }

  .subtitle {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: row;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    min-height: 85px;
    justify-content: start;
  }

  .container:not(:last-child) {
    margin-bottom: 1rem;
    margin-right: 0rem;
  }

  .iconContainer {
    margin-right: 16px;
    margin-bottom: 0px;
  }

  .icon {
    font-size: 24px;
  }

  .textContainer {
    flex-grow: 1;
  }

  .title {
    margin-bottom: 0px;
  }

  .textContainer {
    text-align: start;
  }
}
