@import "../../../../variables.scss";

.container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 8px;
  padding-bottom: 8px;

  height: 3rem;
  width: 2.8rem;
}

.icon {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: -6px !important;

  color: $gray;
}

.container.hasVoted .icon {
  color: $primary;
}

.container:not(.hasVoted):hover .icon {
  color: $grayDark;
}

.icon i {
  font-size: 22px;
}

:global(.theme-dark) {
  .icon {
    color: $grayDark;
  }

  .container.hasVoted .icon {
    color: $primary;
  }

  .container:not(.hasVoted):hover .icon {
    color: $gray;
  }
}
