@import "../../../variables.scss";

.visible {
  visibility: visible;
  opacity: 1;
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

.container {
  position: fixed;
  right: 10px;
  top: 70px;
  width: fit-content;

  -webkit-transition: transform 0.6s $easeInOutQuad, visibility 0.3s,
    opacity 0.3s;
  -moz-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  -ms-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  -o-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
}

.container.completed {
  animation: pulse 2s infinite;
}

.container.completed:hover {
  animation: none;
  transform: scale(1.05);
}

@keyframes pulse {
  0% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1.05);
  }
}

.innerContainer {
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;

  display: flex;
  flex-direction: column;

  cursor: pointer;

  overflow: hidden;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;

  margin-top: 4px;
  padding-left: 16px;
  padding-right: 16px;

  font-size: 12px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.title span {
  color: $secondary;
}

.title .text {
  font-weight: bold;
}

.progressBar {
  height: 4px;
  background-color: $secondary;

  -webkit-transition: width 0.6s;
  -moz-transition: width 0.6s;
  -ms-transition: width 0.6s;
  -o-transition: width 0.6s;
  transition: width 0.6s;
}

.progressContainer {
  padding-left: 16px;
  padding-right: 16px;
}

.goal.finished .text {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

.progressContainer .goal {
  display: flex;
  min-width: 150px;
}

@media screen and (max-width: 768px) {
  .progressContainer .goal {
    font-size: 14px;
  }
}

.progressContainer .counter {
  flex-grow: 1;
  text-align: end;
}

.progressContainer .goal:not(.finished) .counter {
  font-weight: bold;
}

:global(.theme-dark) {
  .innerContainer {
    color: $dark-foreground;
  }
}
