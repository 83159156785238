.container {
  display: flex;
  flex-direction: column;
}

.title {
  align-self: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 16px;
  font-size: 20px;
  text-align: center;
  // font-weight: bold;
}

.jobsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .jobsContainer {
    flex-direction: column;
  }
}
