@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
}

.suggestionsTitle {
  align-self: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3);
}

.suggestionsContainer {
  position: relative;
}

.spinnerContainer {
  position: absolute;
  transition: opacity 0.2s;
  opacity: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.spinnerContainer.show {
  opacity: 0.2;
  transition: opacity 2s;
}

.suggestionsListContainer {
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.2s;
}

.suggestionsListContainer.loading {
  opacity: 0;
}

.stickyHeaderNewEntry {
  margin-bottom: 1.5rem;
}

:global(.theme-dark) .stickyHeaderNewEntry {
  background-color: $dark-elevated-3;
}
