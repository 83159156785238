@import "../../../../../variables.scss";

.container {
  width: fit-content;
  display: flex;
}

.container:not(:last-child) {
  margin-bottom: 8px;
}

.container.lastMessage:not(:last-child) {
  margin-bottom: 20px;
}

.messageContainer {
  padding: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  border-radius: 6px;
  white-space: pre-wrap;
}

.image {
  align-self: flex-end;
}

.fromSelf {
  align-self: flex-end;
  margin-left: 36px;
}

.fromOther {
  align-self: flex-start;
  margin-right: 36px;
}

.fromSelf.noImage {
  margin-right: 40px;
}

.fromOther.noImage {
  margin-left: 40px;
}

.messageSelf {
  border-bottom-right-radius: 0px;
  background-color: $primary;
  color: white;
}

.messageOther {
  border-bottom-left-radius: 0px;
  background-color: $grayLight;
}

.imageSelf {
  margin-left: 6px;
}

.imageOther {
  margin-right: 6px;
}

.fromSelf a.linkText {
  color: white;
  opacity: 0.7;
  font-weight: bold;
}

.fromSelf a.linkText:hover {
  color: white;
  opacity: 1;
  font-weight: bold;
}

:global(.theme-dark) {
  .messageSelf {
    background-color: $primary_dark;
  }

  .messageOther {
    color: $dark-foreground;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
