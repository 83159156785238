@media only screen and (max-width: 768px) {
  #levelRight {
    margin-top: 8px;
  }
}

.iconGray {
  color: #b5b5b5;
}

.menuBar {
  width: auto;
  height: wrap content;
  padding: 4px;
}

.menuBarInnerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -12px;
}

.menuBarLeft,
.menuBarRight {
  display: flex;
  margin-bottom: 12px;
}

.menuBarLeft {
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.menuBarRight {
  align-items: center;
  justify-content: flex-end;
}

.menuBarItem {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.menuBarItem:not(:last-child) {
  margin-right: 6px;
}

.fadeIn {
  -webkit-transform: translate3d(0, 0, 0);

  -webkit-animation: fadeIn ease 0.4s;
  -moz-animation: fadeIn ease 0.4s;
  -o-animation: fadeIn ease 0.4s;
  -ms-animation: fadeIn ease 0.4s;
  animation: fadeIn ease 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
