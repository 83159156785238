@import "../../../../variables.scss";

#SpinnerWrapper {
  height: 32px;
  display: flex;
  justify-content: center;
}

p {
  color: black;
}

.SearchResultItem {
  width: auto;
  height: 30px;
  padding-bottom: 4px;
  padding-top: 4px;
  margin-bottom: 2px !important;
  display: flex;
  align-items: center;
}

.SearchResultItem:focus {
  outline: none;
  background: whitesmoke;
}

.SearchResultItem:hover {
  background: whitesmoke;
}

:global(.theme-dark) .SearchResultItem:hover,
:global(.theme-dark) .SearchResultItem:focus {
  background: rgba(255, 255, 255, 0.1);
}

.SearchResultItemText {
  display: inline;
  font-size: 16px;
  margin-left: 8px;
}

.SearchResultItemImage {
  width: 30px;
  height: 30px;
  max-height: 30px;
}

.SearchResultItemEmptyImage {
  background: whitesmoke;
  color: rgb(181, 181, 181);
}

:global(.theme-dark) .SearchResultItemEmptyImage {
  background: $dark-elevated-11;
}

.TextNoResults {
  color: rgb(181, 181, 181);

  padding-top: 4px;
  padding-bottom: 4px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.categoryIndicator {
  height: 30px;
  width: 4px;
  margin-right: -4px;
}
