@import "../../variables.scss";

.container {
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  overflow-y: hidden;
}

.contentContainer {
  display: flex;
  flex-flow: column;
  margin-top: 100px;
  align-self: center;
  width: 50vw;
}

@media screen and (max-width: $max-width-mobile) {
  .contentContainer {
    width: 90vw;
  }
}

@media screen and (min-width: $max-width-mobile) and (max-width: $max-width-mobile-and-tablet) {
  .contentContainer {
    width: 80vw;
  }
}
