@import "../../../../variables.scss";

.loadingContainer {
  padding: 16px;
}

.bodyContainer {
  display: flex;

  flex-direction: column;
  align-items: center;
}

.listContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.iconGray {
  color: $gray;
}

.newCategoryButton {
  margin-top: 16px;
}
