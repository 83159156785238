@import "../../../../variables.scss";

.button {
  overflow: hidden;
}

.iconGray {
  color: $gray;
}

:global(.theme-dark) .iconGray {
  color: $grayDark;
}
