.container {
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feedbackContainer {
  max-height: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  margin-bottom: 12px;
  padding-top: 6px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  flex-grow: 1;
  align-self: center;
}

.title {
  font-size: 18px;
  align-self: center;
  color: #4a4a4a;
}

.icon {
  align-self: center;
  margin-right: 6px;
  color: #4a4a4a;
}
