@import "../../../../../../variables.scss";

.container {
  padding: 0px;
}
.container:focus {
  outline: none;
}

.deleteLoading {
  opacity: 0.5;
}

.dragHandle:hover {
  cursor: move;
}

.actionDropdown {
  align-self: flex-end;
}

.tagsContainer {
  padding-top: 4px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.referencesContainer {
  padding-top: 4px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.tagsAndReferences .tagsContainer {
  padding-bottom: 8px;
}

.blockTypeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blockProgressContainer {
  margin-left: 10px;
  display: flex;
  align-items: center;
}
