@import "./variables";
@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/react-quill/dist/quill.bubble.css";
@import "../node_modules/react-image-crop/dist/ReactCrop.css";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300italic,400,400italic,700,700italic,900,900italic");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP");
@import "../node_modules/pretty-checkbox/src/pretty-checkbox.scss";

// bulma theming config
html.theme-dark {
  background-color: $dark-background;
}

.theme-dark {
  body {
    color: $dark-foreground;
  }

  .box {
    background-color: $dark-card-background;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  ul {
    color: $dark-foreground;
  }

  hr {
    background-color: $dark-elevated-6;
  }

  .button:not(.is-primary):not(.is-danger):not(.is-success):not(.is-outlined):not(.is-light) {
    background-color: $dark-elevated-11;
    color: $dark-foreground;
  }

  .button:not(.is-light) {
    border-color: rgba(255, 255, 255, 0.2);
  }

  .button:not(.is-light):hover {
    border-color: rgba(255, 255, 255, 0.4);
  }

  .button.is-light {
    background-color: $dark-elevated-9;
    color: $dark-foreground;
  }

  .button.is-light:hover {
    background-color: $dark-elevated-11;
  }

  .button.is-light.is-loading::after {
    border-color: transparent transparent rgba(255, 255, 255, 0.8)
      rgba(255, 255, 255, 0.8) !important;
  }

  .button.is-primary.is-outlined:not(:hover):not(:focus) {
    color: $primary_dark;
  }

  .button.is-outlined:not(.is-primary):not(.is-danger) {
    background-color: transparent;
    color: $dark-foreground;
  }

  .button.is-primary:not(.is-outlined) {
    background-color: $primary_dark;
  }

  .button.is-primary:hover,
  .button.is-primary.is-hovered {
    background-color: $primary_dark2;
  }

  .select select {
    background-color: $dark-elevated-11;
    color: $dark-foreground;
    border-color: rgba(255, 255, 255, 0.4);
  }

  .select select:hover {
    border-color: rgba(255, 255, 255, 0.7);
  }

  .select:hover::after {
    border-color: rgba(255, 255, 255, 0.7) !important;
  }

  .input {
    background-color: $dark-elevated-2;
    color: $dark-foreground;
  }

  .input:not(:focus):not(.is-primary) {
    border-color: rgba(255, 255, 255, 0.2);
  }

  .input:not(:focus):hover {
    border-color: rgba(255, 255, 255, 0.4);
  }

  .input ~ .icon {
    color: rgba(255, 255, 255, 0.4) !important;
  }

  .input:focus ~ .icon {
    color: rgba(255, 255, 255, 0.7) !important;
  }

  .file-cta {
    background-color: $dark-elevated-9;
    color: $dark-foreground;
    border-color: rgba(255, 255, 255, 0.2);
  }

  .file-cta:hover {
    background-color: $dark-elevated-11;
    color: $dark-foreground;
    border-color: rgba(255, 255, 255, 0.4);
  }

  .checkbox:hover {
    color: $dark-foreground;
  }

  .tag.is-light {
    background-color: $dark-elevated-9;
    color: $dark-foreground;
  }

  .modal-card-body {
    background-color: $dark-elevated-1;
  }

  .modal .box {
    background-color: $dark-elevated-4;
  }

  .modal-card-foot,
  .modal-card-head {
    background-color: $dark-elevated-6;
  }

  .modal-card-head {
    border-bottom-color: $dark-elevated-10;
  }

  .modal-card-foot {
    border-top-color: $dark-elevated-10;
  }

  .modal-background {
    background-color: rgba(10, 10, 10, 0.95);
  }

  .quizTextEditor .ql-editor > *:not(.ql-syntax),
  .quizTitleEditor .ql-editor * {
    color: $dark-foreground;
  }

  .ql-editor strong {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 700;
  }

  .ql-editor {
    a {
      color: $primary_dark;
    }

    a:hover {
      color: $primary;
    }
  }

  img,
  image {
    filter: brightness(0.95);
  }
}
// bulma theming config end

html {
  overflow-y: scroll;
}

body {
  color: #4a4a4a;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

.text-align-center {
  text-align: center;
}

.tooltipp {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  opacity: 1 !important;
  background-color: #444 !important;
}

.tooltipp:after {
  border-top-color: #444 !important;
  border-bottom-color: #444 !important;
}

.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: $primary;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: $danger;
}

// fix for bulma too wide on mobile

@media screen and (max-width: 1024px) {
  .columns {
    margin-left: 0;
    margin-right: 0;
  }

  .column {
    padding-left: 0;
    padding-right: 0;
  }

  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: $max-width-mobile) {
  .column.is-11-mobile {
    flex: none;
    width: 99%;
  }
}

// Richtext Editor

.ql-container {
  font-family: "Roboto", sans-serif;
}

.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  border: none;
  border-bottom: 2px solid whitesmoke;
}

.blockContentEditor .ql-container {
  font-size: 15px;
}

.blockContentEditor .ql-editor {
  font-size: 15px;
  padding: 0px;
}

.blockContentEditor {
  color: black;
}

.blockTitleEditor,
.entryNameEditor,
.newEntryEditor {
  width: 100%;
}

.entryNameEditor .ql-container,
.newEntryEditor .ql-container {
  font-size: 24px;
}

.blockTitleEditor .ql-editor,
.entryNameEditor .ql-editor,
.newEntryEditor .ql-editor {
  padding: 0px 0px;
}

.blockTitleEditor .ql-container {
  font-size: 18px;
}

.quizTitleEditor .ql-editor {
  font-size: 18px;
  text-align: center;
  padding: 0px;
  overflow: hidden;
}

.quizTextEditor .ql-editor {
  font-size: 16px;
  padding: 0px;
  overflow: hidden;
}

.quizTextEditor .ql-editor > *:not(.ql-syntax),
.quizTitleEditor .ql-editor * {
  color: black;
}

.quizTextEditor .ql-editor *,
.quizTitleEditor .ql-editor * {
  cursor: pointer;
}

.quizTextEditor .ql-editor > *:not(.ql-syntax),
.dailyQuoteTextEditor .ql-editor > *:not(.ql-syntax) {
  text-align: center;
}

.dailyQuoteTextEditor .ql-editor {
  font-size: 20px;
  padding: 0px;
  line-height: 1.45;
}

@media screen and (max-width: $max-width-mobile) {
  .dailyQuoteTextEditor .ql-editor {
    font-size: 18px;
    padding: 0px;
    line-height: 1.43;
  }
}

.ql-bubble .ql-editor pre.ql-syntax {
  background-color: #282c34;
}

.ql-editor .reveal .hidden {
  visibility: none;
}

.ql-editor {
  overflow-y: visible;
}

.readMode .ql-toolbar {
  display: none;
}

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.3);
  font-style: normal;
  left: 0px;
  right: 0px;
}

.theme-dark .ql-editor.ql-blank::before {
  color: rgba(255, 255, 255, 0.3);
}

.ql-bubble .ql-tooltip {
  background-color: #444;
  z-index: 1000;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.3);
  opacity: 1; /* Firefox */
}

.theme-dark ::placeholder {
  color: rgba(255, 255, 255, 0.3);
  opacity: 1;
}

.ql-tooltip-editor input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.8);
  opacity: 1; /* Firefox */
}

.hidden {
  display: none;
}

.ReactCollapse--collapse {
  -webkit-transition: height 0.1s linear;
  -moz-transition: height 0.1s linear;
  -ms-transition: height 0.1s linear;
  -o-transition: height 0.1s linear;
  transition: height 0.1s linear;
}

.dropdown.is-active .dropdown-menu {
  opacity: 1;
  -webkit-animation: fadeIn 0.2s ease;
  -moz-animation: fadeIn 0.2s ease;
  -o-animation: fadeIn 0.2s ease;
  animation: fadeIn 0.2s ease;
}

@-webkit-keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tippy-box.image-preview {
  background-color: transparent;
}

.tippy-box.image-preview .tippy-content {
  padding: 0px;
}

.tippy-box[data-theme~="graph"] {
  background-color: transparent;
}

.modal-card-foot {
  justify-content: flex-end;
}

.tippy-box.autocomplete .tippy-content {
  padding: 0px;
}

.tippy-box.dropdown .tippy-content {
  padding: 0px;
}

.pretty {
  white-space: normal;
  width: 100%;
}

.pretty .state label {
  text-indent: 0;
  padding-left: 2rem;
}

.pretty .state label:after,
.pretty .state label:before,
.pretty.p-icon .state .icon {
  top: calc(50% - ((1em + 2px) / 2));
}

.modal-card-title {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-shrink: 1;

  padding-top: 2px;
  padding-bottom: 2px;
}

.modal-card-head,
.modal-card-foot {
  max-height: 70px;
}

.modal-card-head {
  padding-right: 10px;
}
