@import "../../../../variables.scss";

.tag {
  user-select: none;
}

.tag.editable {
  cursor: pointer;
}

.tag:focus {
  outline: none;
}
