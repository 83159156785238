@import "../../variables.scss";

.container {
  height: 56px;
  z-index: 3000 !important;
  background: linear-gradient(#007d4b, #00af4b);
  // background: linear-gradient(#00af4b, #007d4b);
}

.container.mobileApp {
  background: $primary;
}

:global(.theme-dark) {
  .container,
  .container.mobileApp,
  .innerContainer {
    background: $dark-elevated-4;
  }

  .menuItemInBurger .menuItemIcon {
    color: $grayDark;
    margin-right: 4px;
  }
}

.menuItemInBurger:focus {
  outline-color: $primary;
}

.menuItemInBurger:hover .menuItemIcon,
.menuItemInBurger:focus .menuItemIcon {
  color: $primary;
}

.menuItem {
  color: rgb(255, 255, 255);
  outline: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.menuItemIcon {
  margin-right: 4px;
}

.menuItem:hover,
.menuItem:focus {
  background-color: rgba(0, 125, 75, 0.3) !important;
}

:global(.theme-dark) {
  .menuItem:hover,
  .menuItem:focus {
    background-color: $dark-elevated-6 !important;
  }
  .menuItem {
    color: $dark-foreground !important;
  }
}

.menuItemInBurger {
  color: rgb(0, 0, 0);
}

.menuItemInBurger .menuItemIcon {
  color: $grayDark;
}

.navbarBurger:focus {
  outline: none;
}

#navMenu {
  overflow: visible;
}

#navMenu:focus {
  outline: none;
}

:global(.theme-dark) {
  #navMenu:global(.is-active) {
    background-color: $dark-elevated-2;
  }
  .menuItemInBurger {
    color: $dark-foreground;
  }
  .menuItemInBurger .menuItemIcon {
    color: $grayDark;
  }

  .menuItemInBurger:hover,
  .menuItemInBurger:focus {
    background-color: rgba(255, 255, 255, 0.1);
    color: $dark-foreground;
    outline: none;
  }
}

.logoContainer {
  padding-top: 0px;
  padding-bottom: 0px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.logoContainer .logoLink {
  height: 100%;
  display: flex;
}

.logoContainer .logo {
  align-self: center;
  justify-content: center;
  height: 38px;
  max-height: 100%;
}

.logoLink:focus {
  outline: none;
}
