@import "../../../../variables.scss";

.entryHeaderInnerContainer {
  padding: 0px;
}

:global(.theme-dark) .entryHeaderInnerContainer {
  background-color: $dark-elevated-3;
}

.contentContainer {
  overflow-anchor: none;
}

.entryContainer:not(:last-child) {
  margin-bottom: 8px;
}
