@import "../../../../../variables.scss";

.mainButton {
  min-width: 160px;
  max-width: 160px;
  margin-right: 6px;
}

.refreshButton .icon {
  color: $gray;
}

.contentContainer {
  display: flex;
  flex-direction: column;
}

.infoText {
  font-size: 0.85rem;
  color: $grayDark;
  margin-top: 10px;
}

.infoText.error {
  color: $danger;
}
