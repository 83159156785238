.container {
  width: auto;
  height: wrap content;
  display: flex;
  flex-direction: column;
  padding: 0px;
  overflow: hidden;
}

#entryContent {
  margin: 0px;
}

.entryHeaderContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.entryInfoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  justify-content: center;
}

.entryNameContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.entryTagsContainer {
  visibility: visible;
  display: block;
  opacity: 1;

  margin-top: 6px;

  -webkit-transition: opacity 0.2s, visibility 0.2s;
  -moz-transition: opacity 0.2s, visibility 0.2s;
  -ms-transition: opacity 0.2s, visibility 0.2s;
  -o-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}

.sticky .entryTagsContainer {
  visibility: hidden;
  display: none;
  opacity: 0;
}

.referencesContainer {
  visibility: visible;
  display: block;
  opacity: 1;

  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;

  -webkit-transition: opacity 0.2s, visibility 0.2s;
  -moz-transition: opacity 0.2s, visibility 0.2s;
  -ms-transition: opacity 0.2s, visibility 0.2s;
  -o-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;

  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.referencesContainer::-webkit-scrollbar {
  display: none;
}

.referencesContainer > div {
  flex-wrap: nowrap;
  overflow-y: hidden;
}

.sticky .referencesContainer {
  visibility: hidden;
  display: none;
  opacity: 0;
}

#container hr {
  margin: 0px;
}

.imageContainer {
  align-self: center;
}

.categoryIndicator {
  width: 0px;
}

.sticky .categoryIndicator {
  width: 7px;
  margin-right: -7px;
}

.categoryContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 2px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.categoryContainer.editable {
  cursor: pointer;
}

.sticky .categoryContainer {
  visibility: hidden;
  display: none;
  opacity: 0;
}

.categoryColorRect {
  height: 10px;
  width: 10px;
  border-radius: 2px;
  margin-right: 4px;
  flex-shrink: 0;
}

.categoryName {
  font-size: 0.75rem;
  padding-right: 6px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  max-width: 180px;
}
