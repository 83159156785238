@import "../../../../../variables.scss";

.container {
  width: auto;
  height: auto;
  padding: 12px;
  margin-bottom: 8px !important;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex-grow: 1;
}

.languageContainer:not(:last-child) {
  margin-bottom: 6px;
}

.language {
  font-weight: bold;
  margin-right: 12px;
}

.dateContainer {
  margin-right: 20px;
}

:global(.theme-dark) {
  .container {
    color: $dark-foreground;
  }
}
