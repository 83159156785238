@import "../../../../../variables.scss";

.container {
  display: flex;
  flex-grow: 1;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 24px;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.inputContainer {
  flex-grow: 1;
}

.input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.button {
  color: $gray;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: auto;
}
