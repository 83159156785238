@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.groupTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.groupColor {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.featuresListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.submitButton {
  margin-left: 18px;
}

.icon {
  color: $gray;
}

:global(.theme-dark) .icon {
  color: $grayDark;
}

@media screen and (max-width: 1023px) {
  .featuresListContainer > a:not(:last-child) {
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    align-items: flex-start;
  }

  .featuresListContainer {
    flex-direction: row;
    flex-wrap: wrap;
    margin: -10px;
  }

  .featuresListContainer > a {
    flex-basis: 45%;
    margin-left: 12px !important;
    margin-right: 12px !important;
    margin-bottom: 12px !important;
    margin-top: 12px !important;
  }
}
