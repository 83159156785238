.container {
  display: flex;
  flex-direction: column;
}

.videoContainer {
  display: flex;
  justify-content: center;
  padding: 0px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  margin: 20px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .videoContainer {
    margin: 8px;
  }
}
