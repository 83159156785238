@import "../../../../variables.scss";

.container {
  display: flex;
  align-items: center;
}

.typeText {
  display: inline-block;
  font-size: 12px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.gray {
  color: $gray;
}

:global(.theme-dark) {
  .typeText {
    color: rgba(255, 255, 255, 0.7);
  }
  .gray {
    color: $grayDark;
  }
}
