@import "../../../../../variables.scss";

.entry {
  height: auto;
  padding: 0px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  background-color: white;
  z-index: 1000;
}

.entryName {
  font-size: 16px;
  margin-left: 8px;
}

.entryImage {
  width: 30px;
  height: 30px;
  background: whitesmoke;
}

.entryEmptyImage {
  background: whitesmoke;
  color: $gray;
}

:global(.theme-dark) {
  .entry {
    background-color: $dark-card-background;
  }

  .entryImage {
    background: $dark-elevated-9;
  }

  .entryEmptyImage {
    background: $dark-elevated-9;
    color: $grayDark;
  }
}
