@import "../../../../variables.scss";

.activeTabContainer {
  min-height: 340px;
  max-height: 340px;
  overflow-y: auto;
}

@media only screen and (max-width: 481px) {
  .activeTabContainer {
    min-height: unset;
    max-height: unset;
    overflow-y: unset;
  }
}

:global(.theme-dark) {
  :global(.tabs) a {
    color: $gray;
    border-bottom-color: $grayDark;
  }

  :global(.tabs) a:hover {
    color: $dark-foreground;
    border-bottom-color: $grayLight;
  }

  :global(.tabs) ul {
    border-bottom-color: $grayDark;
  }
}

.settingsGroup {
  display: flex;
  flex-direction: column;
}

.settingsGroup:not(:last-child) {
  margin-bottom: 42px;
}

.settingContainer {
  display: flex;
  flex-direction: row;
}

.settingContainer:not(:last-child) {
  margin-bottom: 24px;
}

.settingTitle {
  align-self: center;
  margin-right: 24px;
}

.checkboxContainer {
  align-self: center;
}

.shareEntryNamesContainer label {
  max-width: 440px;
}

.groupTitle {
  font-weight: bold;
  margin-bottom: 20px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
}

.emailSettingContainer {
  display: flex;
  flex-direction: row;
}

.exportTitle {
  margin-right: 6px;
}

@media only screen and (max-width: 481px) {
  .emailSettingContainer {
    flex-direction: column;
    align-self: start;
  }

  div.emailSettingContainer:not(:last-child) {
    margin-bottom: 32px;
  }

  div.emailSettingTitleContainer {
    margin-bottom: 14px;
    align-self: start;
  }

  .emailSettingContainer > div {
    align-self: start;
  }

  span.groupTitle {
    margin-bottom: 16px;
  }
}

.emailSettingsContainer {
  display: flex;
  flex-direction: column;
}

.emailSettingContainer:not(:last-child) {
  margin-bottom: 8px;
}

.emailSettingTitleContainer {
  min-width: 200px;
  display: flex;
  flex-direction: row;
}

.emailSettingTitleText {
  margin-right: 6px;
}

.notificationButtonContainer:not(:last-child) {
  margin-bottom: 16px;
}

.emailSettingControlsContainer {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  margin-top: -12px;
}

.emailSettingControlsContainer > * {
  margin-top: 12px;
}

.emailSettingContainer .emailSettingControlsContainer {
  padding-top: 6px;
  padding-bottom: 6px;
}

.hourOfDayPickerContainer {
  transition: opacity 0.2s;
}

.hourOfDayPickerContainer.disabled {
  opacity: 0.4;
}
