@import "../../../../../variables.scss";

.container {
  width: auto;
  height: 60px;
  padding: 0px;
  margin-bottom: 8px !important;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.approvedIndicator {
  width: 4px;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  flex-grow: 1;
}

.id {
  color: rgba($color: #000000, $alpha: 0.2);
  font-weight: bold;
  margin-right: 16px;
}

.name {
  font-size: 16px;
  flex-grow: 1;
}

.extraInfoContainer {
  align-self: flex-end;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
}

.userCountContainer {
  display: flex;
  flex-direction: row;
}

.userCountContainer span:not(:last-child) {
  margin-right: 3px;
}

.variantCountContainer {
  display: flex;
  flex-direction: row;
  margin-right: 12px;
}

.variantCountContainer span:not(:last-child) {
  margin-right: 3px;
}

.gray {
  color: $gray;
}

:global(.theme-dark) {
  .container {
    color: $dark-foreground;
  }

  .gray {
    color: $grayDark;
  }

  .id {
    color: rgba($color: #fff, $alpha: 0.25);
  }
}
