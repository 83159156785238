@import "../../../../../../../variables.scss";

.innerContainer {
  flex-wrap: nowrap;
  overflow-x: auto;
  display: flex;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.innerContainer::-webkit-scrollbar {
  display: none;
}

.container {
  padding: 12px;
  padding-bottom: 12px;
}

.text {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.textContainer {
  margin-bottom: 12px;
}

:global(.theme-dark) {
  .textContainer {
    color: rgba(255, 255, 255, 0.7);
  }
}
