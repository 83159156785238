.container {
  height: 100%;
  width: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+66,0.65+100 */
  background: -moz-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 32px,
    rgba(0, 0, 0, 0) 100
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 32px,
    rgba(0, 0, 0, 0) 100
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 32px,
    rgba(0, 0, 0, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
}
