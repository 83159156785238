@import "../../../../variables.scss";

.blockIcon {
  color: $gray;
}

.blockConnector {
  color: $gray;
  margin-left: 4px;
  margin-right: 4px;
}

.blockText {
  margin-left: 6px;
}
