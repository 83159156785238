@import "../../../variables.scss";

.Error {
  color: $danger;
  text-align: center;
  margin-top: 24px;
}

.resetPasswordText {
  text-align: center;
  margin-bottom: 2rem;
}

.loginContainer {
  text-align: center;
}

.button {
  width: 100%;
}

.moreOptions {
  text-align: center;
  font-size: 14px;
  margin-bottom: 6px;
}
