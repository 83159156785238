@import "../../../../variables.scss";

.progressBar {
  width: 200px;
  margin-right: 6px;
}

@media only screen and (max-width: 768px) {
  .progressBar {
    width: 120px;
  }
}

:global(.theme-dark) {
  .progressBar :global(.rc-progress-line-trail) {
    stroke: $dark-elevated-6;
  }
}
