@import "../../../../variables.scss";

:global(.color-edit) {
  .container {
    display: flex;
    flex-direction: row;
    padding: 4px;
  }

  button:not(:last-child) {
    margin-right: 6px;
  }

  .grayIcon {
    color: $gray;
  }

  .loadingContainer {
    padding: 10px;
  }
}
