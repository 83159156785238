@import "../../../../variables.scss";

.id {
  color: rgb(170, 170, 170);
  margin-right: 8px;
}

.title {
  color: black;
}

.container p {
  margin-top: 6px;
  color: rgb(170, 170, 170);
}

.topContainer {
  display: flex;
  flex-direction: row;
}

.titleContainer {
  flex-grow: 1;
  align-self: center;
}

.gray {
  color: $gray;
}

.userCountContainer {
  display: flex;
  flex-direction: row;
}

.userCountContainer span:not(:last-child) {
  margin-right: 4px;
}

.imagesContainer {
  margin-top: 16px;
}

.imageSearchQueriesContainer {
  margin-top: 8px;
}

:global(.theme-dark) {
  .container p {
    color: rgba(255, 255, 255, 0.7);
  }

  .title,
  .container {
    color: $dark-foreground;
  }

  .gray {
    color: $grayDark;
  }

  .id {
    color: rgba($color: #fff, $alpha: 0.25);
  }
}
