.container {
  display: flex;
  flex-direction: column;
}

.featureGroupsContainer {
  margin-top: 32px;
  margin-bottom: 32px;
}

.featureGroupsContainer > div:not(:last-child) {
  margin-bottom: 32px;
}

.ideasContainer {
  margin-top: 52px;
}
