.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;

  background-color: #f5f5f5;

  margin-top: 3px;
  margin-bottom: 3px;
}

.container:not(:last-child) {
  margin-right: 8px;
}

:global(.tag).container:hover,
:global(.tag).container:focus {
  background-color: rgb(230, 230, 230);
  outline: none;
}

.colorRect {
  height: 14px;
  width: 14px;
  border-radius: 2px;
  margin-right: 4px;
}

.name {
  font-size: 0.75rem;
  padding-right: 6px;
  color: #363636;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

:global(.theme-dark) {
  .container {
    background-color: #333333;
  }

  :global(.tag).container:hover,
  :global(.tag).container:focus {
    background-color: rgb(66, 66, 66);
  }

  .name {
    color: #f5f5f5;
  }
}
