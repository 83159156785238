@import "../../../../variables.scss";

.primaryContainer {
  width: auto;
  height: 120px;
  padding: 0px;
  margin-bottom: 8px !important;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.secondaryContainer {
  width: auto;
  padding: 0px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 40px !important;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.userInfo {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  font-size: 12px;
}

.userName {
  font-size: 20px;
}

.userEmail {
  margin-bottom: 6px;
}

.userImage {
  width: 120px;
  height: 120px;
}

.userEmptyImage {
  background: whitesmoke;
  color: rgb(181, 181, 181);
}

:global(.theme-dark) {
  .userEmptyImage {
    background-color: $dark-elevated-9;
    color: $grayDark;
  }
}
