.container {
  flex-wrap: nowrap;
  overflow-x: auto;
  display: flex;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  min-height: 44px;
}

.container::-webkit-scrollbar {
  display: none;
}
