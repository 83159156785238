@import "../../../../variables.scss";

.sharingCheckboxContainer {
  margin-bottom: 6px;
}

.hintContainer {
  margin-top: 24px;
  padding: 8px;
  display: flex;
  justify-content: center;
}

.hint .icon {
  color: $gray;
  margin-right: 4px;
}

:global(.theme-dark) {
  .hintContainer {
    color: $dark-foreground;
  }

  .hint .icon {
    color: $grayDark;
  }
}
