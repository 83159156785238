@import "../../../../../variables.scss";

.container {
  padding: 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.container.selectable {
  cursor: pointer;
}

.container:focus {
  outline: none;
}

.container.selectable:focus {
  box-shadow: 0 2px 3px rgba($color: $primary, $alpha: 0.6),
    0 0 0 1px rgba($color: $primary, $alpha: 0.6);
}

.container:not(:last-child) {
  margin-bottom: 12px;
}

.colorRect,
.noneRect {
  height: 52px;
  width: 52px;
  flex-shrink: 0;
}

.noneRect {
  background-color: whitesmoke;
  display: flex;
  align-items: center;
}
.textContainer {
  display: flex;
  flex-direction: column;

  margin-left: 12px;
  flex-grow: 1;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.description {
  font-size: 12px;
  color: darkgray;
  margin-top: -3px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.icon {
  color: $gray;
  flex-grow: 1;
}

.loadingContainer {
  margin-right: 14px;
}

.actionDropdown {
  margin-right: 6px;
}

:global(.theme-dark) {
  .noneRect {
    background: rgba(255, 255, 255, 0.1);
  }

  .noneRect .icon {
    color: $grayDark;
  }
}
