@import "../../../../../../../variables.scss";

.imageContainer {
  flex: auto;
  margin: 0.5vw;
}

.imageContainer.horizontal {
  flex: 0 0 auto;
  margin: 0;
}
.imageContainer.horizontal:not(:last-child) {
  margin-right: 6px;
}

.imageWrapper:focus {
  outline: none;
}

.hidden {
  display: none;
}

.imageContainer .image {
  display: block;
  position: relative;

  // width: 320px;
  // width: calc(100vw / 4);
  // height: auto;
  max-height: 300px;
  width: auto;
  // min-width: calc(100vw / 6);
  margin-left: auto;
  margin-right: auto;
  background-color: $grayLight;
}

:global(.theme-dark) .imageContainer .image {
  background-color: rgba(255, 255, 255, 0.05);
}

.imageContainer.horizontal .image {
  max-height: 200px;
  height: 200px;
}

.imageContainer.vertical .image {
  max-height: 240px;
  height: 240px;
}

@media screen and (max-width: $max-width-mobile) {
  .imageContainer .image {
    width: auto;
    height: auto;
    max-height: 300px;
  }

  .imageContainer.horizontal .image {
    max-height: 180px;
  }

  .imageContainer.vertical .image {
    max-height: none;
    height: auto;
  }
}

.imageWrapper {
  position: relative;
  text-align: center;
}

.imageWrapper .gradient {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.imageWrapper:hover {
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .imageWrapper .gradient,
  .imageWrapper .info {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    -ms-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
  }

  .imageWrapper:hover .gradient,
  .imageWrapper:hover .info {
    opacity: 1;
  }
}

.imageWrapper .info {
  position: absolute;
  bottom: 4px;
}
