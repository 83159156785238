@import "../../../../variables.scss";

.resultContainer {
  display: flex;
  flex-flow: row;
  width: 80%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem !important;
  min-height: 200px;
}

.resultContent {
  text-align: center;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: center;
  flex-basis: auto;
}

.emojiContainer {
  margin-top: 16px;
  margin-bottom: 6px;
}

.notificationContainer {
  display: flex;
  flex-flow: column;
  width: 80%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem !important;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .notificationContainer {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .resultContainer {
    min-height: 220px;
  }
}

@media only screen and (min-width: 481px) {
  .resultContainer {
    min-height: 260px;
  }

  .emojiContainer {
    margin-bottom: 10px;
  }

  .expContainer {
    margin-top: 6px;
  }
}

@media only screen and (min-width: 769px) {
  .resultContainer {
    min-height: 300px;
  }

  .emojiContainer {
    margin-bottom: 18px;
  }

  .expContainer {
    margin-top: 8px;
  }
}

.notificationContainer p {
  text-align: center;
  margin-bottom: 8px;
}

.notificationButtonContainer {
  align-self: center;
}

.title {
  font-size: 20px;
}

.subtitle {
  font-size: 16px;
}

.button:not(:last-child) {
  margin-right: 6px;
}

.icon {
  margin-bottom: 14px;
}

.expText {
  font-size: 16px;
}

.expIcon {
  margin-left: 1px;
  color: $primary;
}

:global(.theme-dark) {
  .expText {
    color: rgba(255, 255, 255, 0.7);
  }

  .expIcon {
    color: $primary_dark;
  }
}
