#entryBrowser {
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: calc(100vh - 40px);
  margin-top: 0px;
}

.contentContainer {
  padding-top: 0px;
  padding-bottom: 100px;
  z-index: 2500;
}

.selectionAndMenuWrapper {
  padding: 0px;
}

.stickyHeaderEntry {
  margin-bottom: 12px;
}

.overflowHidden {
  overflow: hidden;
}

.selectionAndMenuWrapper hr {
  margin: 0px;
}

.selectedEntryContentWrapper {
  margin-bottom: 24px;
  overflow-anchor: none;
}

.selectedEntryContentDistanced {
  margin-bottom: 48px;
}

.newButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  align-self: center;
}

.entryListWrapper {
  display: flex;
  flex-direction: column;
  overflow-anchor: none;
  margin-top: 24px;
}

.entryContainer:not(:last-child) {
  margin-bottom: 10px;
}

.blockHeaderFirstRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.blockType {
  padding-left: 8px;
}

.blockHeaderLeft {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.blockHeaderButtons {
  display: flex;
  flex-direction: row;
  align-self: start;
  margin-top: 4px;
  margin-right: 4px;
}

.blockHeaderTypeAndTitle {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-grow: 1;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
}

.extraMarginLeft {
  margin-left: 8px;
}

.blockTitleContainer {
  padding-left: 6px;
  padding-right: 8px;
  margin-top: 2px;
}

.blockContentContainer {
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 16px;
}

.blockHeader hr {
  margin-top: 0px;
  margin-bottom: 16px;
}

.quizCardDivider {
  margin-top: 8px;
  margin-bottom: 8px;
}
