$color_n_1: #009f4b;
$color_n_2: #ffc800;
$color_n_3: rgb(196, 161, 101);
$color_n_4: #d9aa09;
$color_n_5: rgb(134, 107, 60);

$primary: $color_n_1;
$primary_light: $color_n_1;
$primary_dark: #00863f;
$primary_dark2: #007a39;
$secondary: $color_n_2;
$secondary_dark: $color_n_4;
$third: $color_n_3;
$third_dark: $color_n_5;
$link: $color_n_1;
$success: $color_n_1;
$danger: #cf1836;
$info: rgb(104, 177, 226);
$warning: rgb(252, 216, 71);
$gray: #b5b5b5;
$grayLight: #f5f5f5;
$grayDark: #777777;

$box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
$box-link-hover-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px $link;

$dropdown-content-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
  0 0 0 1px rgba(10, 10, 10, 0.1);

$link-focus-border: $primary;

$pretty--color-primary: $primary;

$max-width-mobile: 481px;
$max-width-mobile-and-tablet: 1023px;

// darkmode
$dark-background: #121212;
$dark-foreground: rgb(230, 230, 230);

$dark-elevated-0: #171717;
$dark-elevated-1: #1f1f1f;
$dark-elevated-2: #212121;
$dark-elevated-3: #242424;
$dark-elevated-4: #272727;
$dark-elevated-5: #2c2c2c;
$dark-elevated-6: #2e2e2e;
$dark-elevated-7: #2c2c2c;
$dark-elevated-8: #2e2e2e;
$dark-elevated-9: #333333;
$dark-elevated-10: #353535;
$dark-elevated-11: #383838;

$dark-card-background: $dark-elevated-2;

// darkmode end

$graph_height_default: 66vh;

$graph_height_quiz: 25vh;
$graph_height_quiz_mobile: 15vh;

$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

:export {
  primaryColor: $primary;
  primaryLightColor: $primary_light;
  primaryDarkColor: $primary_dark;
  linkColor: $link;
  secondaryColor: $secondary;
  secondaryDarkColor: $secondary_dark;
  thirdColor: $third;
  thirdDarkColor: $third_dark;
  gray: $gray;
  grayLight: $grayLight;
}
