@import "../../../../variables.scss";

.icon {
  color: $gray;
}

:global(.theme-dark) {
  .icon {
    color: $grayDark;
  }
}
