@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: row;
}

.tagsContainer {
  max-height: 65px;
  overflow-y: auto;
}

.tagsContainer:not(:last-child) {
  margin-bottom: 0;
}

.plusButton {
  font-weight: bold;
  color: $gray !important;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  -moz-transition: opacity 0.2s, visibility 0.2s;
  -ms-transition: opacity 0.2s, visibility 0.2s;
  -o-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}

.plusButton:hover {
  background-color: rgb(240, 240, 240) !important;
}

.plusButton:active {
  background-color: rgb(235, 235, 235) !important;
}

.plusButton:focus {
  outline: none;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.autoCompleteContainer {
  margin-bottom: 0.5rem;
}

.input {
  border: none;
  min-width: 200px;
}

.input:focus {
  outline: none;
}

:global(.theme-dark) {
  .plusButton {
    background-color: $dark-elevated-9 !important;
  }

  .plusButton:hover {
    background-color: $dark-elevated-11 !important;
  }

  .plusButton:active {
    background-color: $dark-elevated-11 !important;
  }

  .input {
    background-color: transparent;
    color: $dark-foreground;
  }
}
