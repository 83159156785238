@import "../../../../variables.scss";

.icon {
  color: $gray;
}

.active .icon {
  color: $primary !important;
}

// .active {
//   background-color: $primary !important;
// }

// .active:hover,
// .active:focus {
//   background-color: $primary_dark !important;
// }

// .active .icon {
//   color: white !important;
// }

:global(.theme-dark) {
  .icon {
    color: $grayDark;
  }
  // .active {
  //   background-color: $primary_dark !important;
  // }

  // .active:hover,
  // .active:focus {
  //   background-color: $primary_dark2 !important;
  // }

  // .active .icon {
  //   color: $dark-foreground !important;
  // }
}
