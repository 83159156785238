.imageContainer {
  display: flex;
  position: relative;
}

.image {
  flex-grow: 1;
  object-fit: contain;
  max-height: 80vh;
}

@media screen and (min-width: 769px), print {
  .modalCard {
    width: auto;
    min-width: 640px;
  }
}

.imageContainer .gradient {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1024px) {
  .imageContainer .gradient,
  .imageContainer .info {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    -ms-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
  }

  .imageContainer:hover .gradient,
  .imageContainer:hover .info {
    opacity: 1;
  }
}

.imageContainer .info {
  position: absolute;
  bottom: 4px;
  margin-left: 6px;
}
