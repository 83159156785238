@import "../../../../../../variables.scss";

.container {
  padding: 4px;
}

.container:focus {
  outline: none;
}

.activeButton {
  margin-left: 6px;
}

.gray {
  color: $gray;
}

.statsContainer {
  width: fit-content;
  margin-left: auto;
  margin-right: 6px;
}
