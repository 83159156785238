@import "../../../variables.scss";

.text {
  text-align: center;
  margin-bottom: 2rem;
}

.backContainer {
  text-align: center;
}

.Error {
  color: $danger;
  text-align: center;
  margin-top: 24px;
}

.success {
  color: $primary;
}

.privacyNote {
  font-size: 12px;
  color: gray;
}
