@import "../../../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  position: relative;
  background-color: whitesmoke;
}

.spinnerContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
}
