@import "../../../../variables.scss";

.bodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
}

.quoteContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 16px;
  margin-right: 16px;
}

.blockTitle {
  text-align: center;
  margin-bottom: 8px;
  font-size: 14px;
}

.sourceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 26px;
  padding-bottom: 16px;
  padding-left: 12px;
  padding-right: 12px;
}

.blockImageContainer {
  margin-right: 10px;
}

.sourceName {
  color: black;
  opacity: 0.8;
  font-size: 18px;
}

:global(.theme-dark) .sourceName {
  color: $dark-foreground;
}

.dash {
  font-size: 18px;
  padding-right: 8px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.footer {
  justify-content: unset !important;
  display: flex;
  flex-direction: row;
}

.buttonsContainerLeft,
.buttonsContainerRight {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.buttonsContainerLeft {
  justify-content: flex-start;
}

.buttonsContainerRight {
  justify-content: flex-end;
}

.actionDropdown {
  margin-right: 0.5rem;
}

.contentContainer {
  -webkit-animation: fadeIn 0.8s ease 0.6s 1 normal forwards running;
  -moz-animation: fadeIn 0.8s ease 0.6s 1 normal forwards running;
  -o-animation: fadeIn 0.8s ease 0.6s 1 normal forwards running;
  animation: fadeIn 0.8s ease 0.6s 1 normal forwards running;
  opacity: 0;
}

.sourceContainer {
  animation: fadeIn 0.8s ease 1.6s 1 normal forwards running;
  opacity: 0;
}

@media screen and (max-width: $max-width-mobile) {
  .quoteContainer {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sourceContainer {
    margin-top: 22px;
  }

  .dash {
    font-size: 16px;
  }

  .sourceName {
    font-size: 16px;
  }

  .blockTitle {
    font-size: 12px;
  }
}

:global(.theme-dark) .dash {
  color: rgba(255, 255, 255, 0.2);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
