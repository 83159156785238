.quiz {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

.quizInitial {
  min-height: calc(100vh - 40px);
}

.quizNoScroll {
  min-height: 0;
}

.quizHeader {
  padding: 0px;
}

.cardContainer {
  display: flex;
  flex-flow: column;
  width: 80%;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  min-height: 260px;
}

@media only screen and (max-width: 768px) {
  .cardContainer {
    max-height: 60vh;
    width: 100%;
  }
}

.cardContainer:focus {
  outline: none;
}

.cardContentContainer {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 52px - 25vh - 50px - 68px - 78px);
  overflow-y: auto;
}

@media only screen and (max-width: 1408px) {
  .cardContentContainer {
    max-height: calc(100vh - 52px - 15vh - 50px - 68px - 78px);
  }
}

.buttonsContainer {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;

  position: absolute;
  bottom: 24px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
