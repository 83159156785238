@import "../../../../variables.scss";

.noTransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.learnButtonColored {
  background-color: whitesmoke;
  border-color: $secondary;
  border-width: 2px;
  color: #363636;

  -webkit-transition: background 0.2s, background-color 0.2s;
  -moz-transition: background 0.2s, background-color 0.2s;
  -ms-transition: background 0.2s, background-color 0.2s;
  -o-transition: background 0.2s, background-color 0.2s;
  transition: background 0.2s, background-color 0.2s;
}

.learnButtonColored:hover {
  background-color: $secondary;
  border-color: transparent;
  color: #ffff;
}

.learnButtonColored:hover .learnIconColored {
  color: #ffff;
}

.learnButtonTextColored {
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

.learnIcon {
  color: $gray;
}

.learnIconColored {
  color: $secondary;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  -ms-transition: color 0.2s;
  -o-transition: color 0.2s;
  transition: color 0.2s;
}

:global(.theme-dark) {
  .learnIcon {
    color: $grayDark;
  }

  .learnButtonColored:hover .learnIconColored {
    color: $secondary;
  }
}
