@import "../../../variables.scss";

.Error {
  color: $danger;
  text-align: center;
  margin-top: 24px;
}

.moreOptions {
  text-align: center;
  font-size: 14px;
  margin-bottom: 6px;
}

.registerContainer {
  text-align: center;
}

.loginButton {
  width: 100%;
}

.separatorContainer {
  margin-top: 16px;
  margin-bottom: 16px;
}
