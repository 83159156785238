@import "../../../../variables.scss";

.inputContainer:not(:last-child) {
  margin-bottom: 12px;
}

.invisible {
  display: none;
}

.errorMessage {
  margin-top: 6px;
  color: $danger;
}
