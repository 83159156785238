@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: none;
  margin-right: 0px !important;
  background-color: whitesmoke !important;
}

.container:focus {
  outline: none;
}

.container.hovered {
  background-color: #f0f0f0 !important;
}

.deleteLoading {
  opacity: 0.5;
}

.iconContainer {
  margin-left: 4px;
  margin-right: 14px;
}

.description {
  margin-right: 8px;
}

.icon {
  color: $gray;
}

.image {
  margin-right: 8px;
}

.imagePlaceholder {
  color: whitesmoke;
  font-size: 13px;
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px;
  background: $gray;
}

:global(.theme-dark) {
  .container {
    background-color: $dark-elevated-9 !important;
  }

  .container.hovered {
    background-color: $dark-elevated-11 !important;
  }

  .icon {
    color: $grayDark;
  }

  .imagePlaceholder {
    color: $grayDark;
    background: rgba(255, 255, 255, 0.08) !important;
  }
}
