@import "../../../../../../../variables.scss";

.container {
  display: flex;
  padding-top: 8px;
  padding-bottom: 16px;
}

.container a {
  flex-grow: 1;
}

.webLinkContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  max-height: 160px;
  color: #4a4a4a;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -ms-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.webLinkContainer:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.webLinkContainer:focus {
  outline: none;
}

.webLinkImageContainer {
  display: flex;
  max-width: 150px;
  min-width: 150px;
  height: 120px;
  align-self: center;
}

.webLinkImage {
  object-fit: cover;
}

.webLinkEmptyImage {
  background-color: rgba(0, 0, 0, 0.04);
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.webLinkTextContainer {
  padding: 20px;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.webLinkTitle {
  font-size: 18px;
  color: black;
}

.webLinkSourceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
}

.webLinkIcon {
  height: 14px;
  width: 14px;
  margin-right: 6px;
}

.webLinkSource {
  color: $grayDark;
  font-size: 14px;
}

@media screen and (max-width: 481px) {
  .webLinkImageContainer {
    display: flex;
    max-width: 125px;
    min-width: 125px;
    height: 100px;
  }
}

:global(.theme-dark) {
  .webLinkContainer {
    color: $dark-foreground;
  }

  .webLinkTitle {
    color: $dark-foreground;
  }

  .webLinkSource {
    color: rgb(160, 160, 160);
  }

  .webLinkContainer:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .webLinkEmptyImage {
    background: rgba(255, 255, 255, 0.1);
  }
}

.emptyContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: whitesmoke;
  min-height: 160px;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.emptyContainer:focus {
  outline: none;
}

.emptyContainer p {
  color: rgba(0, 0, 0, 0.7);
  align-self: center;
  margin-top: 8px;
}

.emptyIcon {
  color: $gray;
  z-index: 500;
  align-self: center;
}

:global(.theme-dark) {
  .emptyContainer {
    background: rgba(255, 255, 255, 0.1);
  }

  .emptyIcon {
    color: $grayDark;
  }

  .emptyContainer p {
    color: rgba(255, 255, 255, 0.7);
  }
}
