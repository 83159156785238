@import "../../../../../../../variables.scss";

.button {
  margin-left: 6px;

  opacity: 1;
  visibility: visible;

  -webkit-transition: opacity 0.2s, visibility 0.2s;
  -moz-transition: opacity 0.2s, visibility 0.2s;
  -ms-transition: opacity 0.2s, visibility 0.2s;
  -o-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}

.gray {
  color: $gray;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

:global(.theme-dark) {
  .gray {
    color: $grayDark;
  }

  .plus {
    color: $grayDark;
  }
}
