:global(.category-preview) {
  .container {
    display: flex;
    flex-direction: column;
    padding: 4px;
    max-width: 200px;
  }

  .nameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    text-align: center;
  }

  .colorRect {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    margin-right: 6px;
    flex-shrink: 0;
  }

  .description {
    margin-top: 4px;
    font-size: 0.75rem;
    align-self: center;
    text-align: center;
  }
}
