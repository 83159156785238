@import "../../../../variables.scss";

.sendMessageContainer {
  display: flex;
  flex-grow: 1;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 6px;
}

.messageInputContainer {
  flex-grow: 1;
}

.messageInput {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.sendButton {
  color: $gray;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
