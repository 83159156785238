@import "../../../variables.scss";

.contextButtonsContainer {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.editButtonsContainer {
  display: flex;
  flex-direction: row;
  margin-left: 12px;
}

.editButtonsContainer button:not(:last-child) {
  margin-right: 6px;
}

.changeStatusButton {
  margin-left: 6px;
}

.grayIcon {
  color: $gray;
}

.commentsContainer {
  margin-top: 32px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

:global(.theme-dark) .grayIcon {
  color: $grayDark;
}
