@import "../../../../../variables.scss";

.container {
  width: 100%;
  padding: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.container:focus {
  outline: none;
}

.container:hover {
  box-shadow: $box-shadow !important;
}

.container:hover,
.container:focus {
  background-color: hsl(0, 0%, 99%);
  box-shadow: $box-shadow !important;
}

.title {
  margin-left: 18px;
  flex-grow: 1;
}

.comments {
  align-self: center;
  margin-left: 16px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
}

.comments span {
  align-self: center;
}

.icon {
  color: $gray;
}

.commentsAmount {
  margin-left: 4px;
}

:global(.theme-dark) {
  .container:hover,
  .container:focus {
    background-color: $dark-elevated-3;
  }

  .icon {
    color: $grayDark;
  }
}
