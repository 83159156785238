@import "../../../../../variables.scss";

.container {
  flex-wrap: nowrap;
  overflow-x: auto;
  display: flex;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.imageQueryContainer:not(:last-child) {
  margin-right: 4px;
}

.gray {
  color: $gray;
}

:global(.theme-dark) {
  .container :global(.tag) {
    background-color: $dark-elevated-9;
    color: rgba(255, 255, 255, 0.7);
  }
}
