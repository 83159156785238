@import "../../../../../variables.scss";

.container {
  width: auto;
  height: 60px;
  padding: 0px;
  margin-bottom: 8px !important;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.image {
  width: 60px;
  height: 60px;
  background: whitesmoke;
  color: $gray;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.userName {
  font-size: 16px;
}

.timestamp {
  font-size: 12px;
}

:global(.theme-dark) {
  .image {
    background-color: $dark-elevated-9;
    color: $grayDark;
  }
}
