@import "../../../../../variables.scss";

.container {
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  padding: 14px !important;
}

.container:not(:last-child) {
  margin-bottom: 12px;
}

.authorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.authorName {
  margin-left: 8px;
}

.text {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.timeAgo {
  font-size: 12px;
  opacity: 0.4;
}

.bottomContainer {
  display: flex;
  flex-direction: row;
}

.editOptionsContainer {
  display: flex;
  flex-direction: row;
}

.editOptionsContainer a {
  font-size: 12px;
  margin-left: 10px;
}

.editOptionsContainer a:not(:last-child) {
  margin-right: 2px;
}

.input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 10px;
}

.editTextContainer {
  display: flex;
  flex-direction: column;
}

.editTextButtonsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.editTextButtonsContainer .button:not(:last-child) {
  margin-right: 6px;
}

.updatedIndicator {
  margin-left: 6px;
  font-size: 12px;
  color: $gray;
  align-self: center;
}
