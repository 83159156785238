@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
}

.createNewContainer {
  margin-bottom: 24px;
}

.gray {
  color: $gray;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
}

.backButtonContainer {
  flex-grow: 1;
}

.updateButton {
  align-self: flex-end;
  margin-bottom: 12px;
}
