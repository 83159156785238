@import "../../../variables.scss";

.Error {
  color: $danger;
  text-align: center;
  margin-top: 24px;
}

.resetButton {
  width: 100%;
}
