@import "../../../variables";

.icon {
  color: $gray;
}

:global(.theme-dark) .icon {
  color: $grayDark;
}

.button {
  position: relative;
  opacity: 1;
  visibility: visible;

  -webkit-transition: opacity 0.2s, visibility 0.2s;
  -moz-transition: opacity 0.2s, visibility 0.2s;
  -ms-transition: opacity 0.2s, visibility 0.2s;
  -o-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}

.button.active:global(.is-light) {
  background-color: rgb(235, 235, 235) !important;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.small {
  height: 14px;
  width: 34px;
  font-size: 0.65rem;
  transform: translateY(5px);
}

:global(.theme-dark) {
  .icon {
    color: $grayDark;
  }

  .button {
    box-shadow: none !important;
  }

  .button.active {
    background-color: $dark-elevated-11 !important;
  }
}
