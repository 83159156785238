@import "../../../../../../variables.scss";

.breadcrumbContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: -6px;
}

.breadcrumb {
  margin-right: 6px !important;
  margin-bottom: 6px;
}

.iconGray {
  color: $gray;
}

.userName {
  margin-left: 8px;
}

.breadcrumb :global(.image) {
  background: rgba(0, 0, 0, 0.05) !important;
}

.dots {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

:global(.theme-dark) {
  .iconGray {
    color: $grayDark;
  }
  .text {
    color: $dark-foreground;
  }

  .breadcrumb :global(.image) {
    background: rgba(255, 255, 255, 0.1) !important;
  }
}
