@import "../../../variables.scss";

.container {
  width: auto;

  padding: 0px;
  margin-bottom: 8px !important;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.container:focus {
  outline: none;
  box-shadow: 0 2px 3px rgba($color: $primary, $alpha: 0.6),
    0 0 0 1px rgba($color: $primary, $alpha: 0.6);
}

.topContainer {
  display: flex;
  align-items: center;
  height: 40px;
  flex-grow: 1;
}

.detailsContainer {
  max-height: 100px;
  min-height: 45px;
  overflow-y: auto;
  padding: 12px;
}

.clickable {
  cursor: pointer;
}

.selected {
  background-color: $primary;
}

.name {
  font-size: 18px;
  margin-left: 18px;
  line-height: 18px;
}

.parentEntryNameContainer {
  display: inline-block;
  margin-bottom: 0px !important;
}

.parentEntryName {
  opacity: 0.4;
}

.nameSeperator {
  opacity: 0.4;
  margin-left: 8px;
  margin-right: 8px;
}

.selected .name {
  color: white;
}

.image {
  width: 40px;
  height: 40px;
  background: whitesmoke;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.emptyImage {
  background: whitesmoke;
  color: rgb(181, 181, 181);
}

.categoryIndicator {
  height: 100%;
  width: 7px;
  margin-right: -7px;
}

:global(.theme-dark) {
  .emptyImage {
    color: $grayDark;
    background: $dark-elevated-8;
  }
}
