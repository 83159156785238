@import "../../../../variables.scss";

.container {
  width: 55px;
  height: 55px;
  border-radius: 6px;
  border-color: $grayLight;
  border-width: 1px;
  border-style: solid;

  display: flex;
  align-items: center;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  cursor: pointer;
}

.container:focus {
  outline: none;
}

.icon {
  color: #fff;
  flex-grow: 1;
  opacity: 0.6;
}

.icon.light {
  color: #000;
}

:global(.theme-dark) {
  .container {
    border-color: rgba(245, 245, 245, 0.2);
  }
}
