@import "../../../variables.scss";

.suggestionsContainer {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  max-height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
}

.suggestionItem {
  display: block;
  font-size: 1rem;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.suggestionItem:focus {
  outline: none;
}

.suggestionItem:not(:last-child) {
  margin-bottom: 0px;
}

.activeSuggestionItem {
  background-color: rgb(240, 240, 240);
}

:global(.theme-dark) {
  .activeSuggestionItem {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
