@import "../../../../../variables.scss";

.searchContainer {
  background: #ffffff;
  padding: 20px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
}

:global(.theme-dark) .searchContainer {
  background-color: $dark-elevated-1;
}

.searchContainer.onDrop {
  outline: 6px solid $third;
  outline-offset: -6px;
}

.searchTopBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.searchContainer #fileUpload {
  flex-basis: auto;
  flex-shrink: 1;
  flex-grow: 1;
}

.centerAligned {
  justify-content: center;
}

.rightAligned {
  justify-content: flex-end;
}

.fileIconMobile {
  margin-right: 0px;
}
