.container {
  margin-bottom: 100px;
}

.createContainer {
  margin-top: 32px;
  margin-bottom: 20px;
}

.variantContainer:not(:last-child) {
  margin-bottom: 12px;
}
