@import "../../../variables.scss";

.Error {
  color: $danger;
  text-align: center;
  margin-top: 24px;
}

.notice {
  margin: 24px 0px 24px 0px;
  text-align: center;
  font-size: 12px;
  color: #4a4a4a;
}

.invitedByText {
  margin-bottom: 32px;
  text-align: center;
}

.loginContainer {
  text-align: center;
}

.noInviteCode {
  font-size: 14px;
  text-align: center;
  margin-top: -6px;
  margin-bottom: 12px;
}

.registerButton {
  width: 100%;
}

.separatorContainer {
  margin-top: 16px;
  margin-bottom: 16px;
}

.moreOptions {
  text-align: center;
  font-size: 14px;
  margin-bottom: 6px;
}
