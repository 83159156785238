@import "../../../../../../../variables.scss";

.sourceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: -4px;
  padding-bottom: 16px;
  padding-left: 12px;
  padding-right: 12px;
}

.source {
  margin-left: 6px;
  border: none;
  outline: none;
  font-family: "Roboto", sans-serif;
  color: black;
  opacity: 0.8;
  font-size: 15px;
  flex-grow: 1;
  background-color: transparent;
}

:global(.theme-dark) .source {
  color: $dark-foreground;
}

.dash {
  padding-right: 8px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

:global(.theme-dark) .dash {
  color: rgba(255, 255, 255, 0.2);
}
