@import "../../variables.scss";

.Error {
  color: red;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(#007d4b, #00af4b);
}

.innerContainer {
  width: 450px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 64px;
  margin-bottom: 64px;
  flex-grow: 1;
}

.authBox {
  background-color: white;
  padding: 42px 48px;
  box-shadow: $box-shadow;
  border-radius: 6px;
}

.logoContainer {
  padding-top: 48px;
  padding-bottom: 38px;
  display: flex;
}

.logoContainer a {
  display: flex;
  margin: auto;
}

.logo {
  height: 60px;
}

.logoLink:focus {
  outline: none;
}

.footerContainer {
  align-self: center;
  color: white;
  margin-bottom: 24px;
}

.footerContainer a {
  // color: #007d4b;
  color: whitesmoke;
}

.footerContainer a:not(:last-child) {
  margin-right: 14px;
}

@media screen and (max-width: $max-width-mobile) {
  .innerContainer {
    width: 100%;
    margin-top: 0px;
  }

  .logoContainer {
    padding-top: 38px;
    padding-bottom: 38px;
    padding-left: 28px;
    padding-right: 28px;
    min-height: 140px;
    height: 25vh;
  }

  .authBox {
    border-radius: 0px;
    box-shadow: none;
    padding: 28px 36px;
  }

  .container {
    background: white;
  }

  .logoContainer {
    // background-color: $primary;
    background: linear-gradient($primary, #007d4b);
  }

  .footerContainer a {
    color: $link;
  }
}

.authTitle {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #363636;
}
