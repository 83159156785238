@import "../../../variables.scss";

.dropdownContainer {
  min-width: 12rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

:global(.theme-dark) .dropdownContainer {
  background-color: $dark-elevated-11;
}
