@import "../../../variables.scss";

.container {
  position: fixed;
  top: 66px;
  right: 10px;
  z-index: 1000;
  opacity: 0;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.container.offsetTop {
  top: 170px;
}

.visible {
  opacity: 1;
}

.expEventContainer {
  background-color: white;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: $box-shadow;
  cursor: pointer;
}

.expEventContainer:focus {
  outline: none;
}

.text {
  font-size: 18px;
}

.iconExp {
  margin-left: 1px;
  color: $primary;
}

:global(.theme-dark) {
  .expEventContainer {
    background-color: $dark-elevated-4;
  }

  .iconExp {
    color: $primary_dark;
  }
}

.iconLevelUp {
  margin-left: 1px;
  color: $secondary;
}

.fadeIn {
  opacity: 1;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-duration: 0.3s;
}

.fadeOut {
  opacity: 0;
  animation-name: fadeOut;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-duration: 0.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
