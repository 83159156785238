@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.listContainer {
  display: flex;
  flex-direction: row;
  min-height: 24px;

  flex-wrap: wrap;
}

.loadingContainer {
  display: flex;
  min-height: 24px;
}

.categoryTitle {
  //  font-weight: bold;
  margin-top: 6px;
  color: $gray;
  font-size: 0.75rem;
  margin-right: 12px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.showAll {
  display: flex;
  align-items: center;
}

.showAll p {
  font-size: 0.75rem;
  color: $primary;

  margin-top: 3px;
  margin-bottom: 3px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
}

:global(.tag).plusButton {
  font-weight: bold;
  color: $gray !important;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  align-self: center;
  font-size: 0.5rem !important;

  height: 24px;
  width: 24px;

  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  -moz-transition: opacity 0.2s, visibility 0.2s;
  -ms-transition: opacity 0.2s, visibility 0.2s;
  -o-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}

:global(.tag).plusButton:hover {
  background-color: rgb(240, 240, 240) !important;
}

:global(.tag).plusButton:active {
  background-color: rgb(235, 235, 235) !important;
}

:global(.tag).plusButton:focus {
  outline: none;
  background-color: rgb(235, 235, 235) !important;
}

:global(.tag).plusButton.hidden {
  opacity: 0;
  visibility: hidden;
}

:global(.theme-dark) {
  .plusButton {
    background-color: $dark-elevated-9 !important;
  }

  .plusButton:hover {
    background-color: $dark-elevated-11 !important;
  }

  .plusButton:active {
    background-color: $dark-elevated-11 !important;
  }
  .plusButton:focus {
    outline: none;
    background-color: $dark-elevated-11 !important;
  }
}
