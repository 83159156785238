@import "../../../../../../../variables.scss";

.cardEditor {
  margin-top: 12px;
}

.sidesDivider {
  margin-top: 16px;
  margin-bottom: 16px;
}

.cardSideTitle {
  color: $gray;
  font-size: 12px;
}

.backTitleContainer {
  display: flex;
}

.centerVertically {
  align-self: center;
}

.checkbox {
  font-size: 12px;
  margin-left: 12px;
  color: $gray;
}

.checkbox:hover {
  color: $gray;
}

.cardContentContainer {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
}

.cardHeaderRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cardHeaderLeft {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.cardHeaderButtons {
  display: flex;
  flex-direction: row;
  align-self: start;
}

.cardHeaderTitle {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-grow: 1;
}

.imageContainer {
  display: flex;
  margin-top: 12px;
}

.deleteImageButton {
  margin-left: 12px;
  justify-content: center;
  align-self: center;
}

.grayIcon {
  color: $gray;
}
