@import "../../../../../variables.scss";

.imagesContainer {
  flex-wrap: nowrap;
  overflow-x: auto;
  display: flex;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.image {
  max-height: 120px;
  position: relative;
}

.imageContainer:not(:last-child) {
  margin-right: 4px;
}

.innerContainer {
  position: relative;
}

.innerContainer .userCountContainer {
  position: absolute;
  bottom: 6px;
  right: 0px;
}

.userCountContainer span {
  color: white;
  font-size: 12px;
  align-self: center;
}

.userCountContainer {
  display: flex;
  flex-direction: row;
}
