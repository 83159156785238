@import "../../../variables.scss";

.icon {
  color: $secondary;
  animation: pulse 2.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1.1);
  }
}
