@import "../../../../../../variables.scss";

.container {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.titleText {
  font-size: 14px;
  margin-left: 4px;
  align-self: center;
  color: black;
}

:global(.theme-dark) .titleText {
  color: $dark-foreground;
}

.container svg {
  font-weight: bold;
  font-size: 14px;
  font-family: "Noto Sans JP", sans-serif;
  pointer-events: none;
}

.container svg .backgroundText {
  stroke: $secondary_dark;
  stroke-width: 2px;
  fill: transparent;
  text-anchor: middle;
}

.container svg .foregroundText {
  fill: white;
  text-anchor: middle;
}

.container rect {
  fill: $secondary;
  stroke: $secondary_dark;
  stroke-width: 1px;
}

:global(.theme-dark) {
  .container svg .backgroundText {
    display: none;
  }

  .container svg .foregroundText {
    // fill: $dark-foreground;
    fill: $secondary;
  }

  .container rect {
    fill: $dark-elevated-11;
    stroke: $secondary;
    stroke-width: 1px;
  }
}
