.container {
  align-self: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 40px;
}

.textTitleContainer {
  padding-left: 12px;
  padding-right: 12px;
  align-self: center;
}

.title {
  margin-bottom: 12px;
}

.image {
  width: 160px;
  flex-shrink: 0;
  align-self: center;
}

@media only screen and (max-width: 1408px) {
  .container {
    flex-direction: column;
  }

  .image {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 1409px) {
  .image {
    margin-right: 24px;
    margin-left: 12px;
  }

  .text {
    max-height: 40vh;
    overflow-y: auto;
  }
}
