@import "../../../variables.scss";

.visible {
  visibility: visible;
  opacity: 1;
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

.container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 70px;
  width: fit-content;

  -webkit-transition: transform 0.6s $easeInOutQuad, visibility 0.3s,
    opacity 0.3s;
  -moz-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  -ms-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  -o-transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
  transition: transform 0.6s $easeInOutQuad, visibility 0.3s, opacity 0.3s;
}

.innerContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;

  display: flex;
  flex-direction: column;

  cursor: pointer;
}

.sharedInfo {
  display: flex;
  flex-direction: row;
  align-items: center;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userInfo span {
  margin-left: 8px;
  flex-shrink: 0;
}

.sharedBy {
  margin-right: 12px;
  flex-shrink: 0;
}

:global(.theme-dark) {
  .innerContainer {
    color: $dark-foreground;
  }
}
