@import "../../../variables.scss";

.GraphContainer {
  background-color: #f0f0f0;
  min-height: 100vh;
  align-self: center;
}

.buttonIcon {
  color: $gray;
  height: 10px !important;
  width: 10px !important;
  padding: 0px;
}

.graphButton {
  height: 28px;
}

text,
rect,
circle,
image {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

g {
  outline: none;
}

:global(.singleNode):hover,
:global(.rootNode):hover {
  filter: brightness(0.98);
  -webkit-filter: brightness(0.98);
}

:global(.theme-dark) {
  .GraphContainer {
    background-color: $dark-elevated-0;
  }

  .buttonIcon {
    color: $grayDark;
  }

  :global(.singleNode):hover,
  :global(.rootNode):hover {
    filter: brightness(1.07);
    -webkit-filter: brightness(1.07);
  }
}
