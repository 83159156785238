@import "../../../../variables.scss";

.feedbackContainer {
  width: 400px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
}

@media screen and (max-width: $max-width-mobile) {
  .feedbackContainer {
    width: calc(100vw - 30px);
  }
}

.loadingContainer {
  padding-top: 18px;
  padding-bottom: 18px;
}
