@import "../../../../variables.scss";

.container {
  display: flex;
  flex-grow: 1;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 6px;
}

.inputContainer {
  flex-grow: 2;
}

.inputParentContainer {
  flex-grow: 1;
  flex-basis: 100px;
}

.inputName {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.inputParent {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.button {
  color: $gray;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
