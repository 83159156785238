@media screen and (min-width: 769px), print {
  .modalCard {
    min-width: 700px;
  }
}
.modalBody {
  display: flex;
  flex-direction: column;

  padding: 0px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  opacity: 1;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.pageContainer.animated {
  opacity: 0;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  min-height: 50px;
}

.footerItemContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}

.backButtonContainer {
  justify-content: flex-start;
}

.nextButtonContainer {
  justify-content: flex-end;
}

.progressContainer {
  justify-content: center;
}

.text {
  align-self: center;
  padding: 20px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 18px;
  text-align: center;
  white-space: pre-line;
}
