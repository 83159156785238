@import "../../../../variables.scss";

.container svg {
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  pointer-events: none;
}

.container svg .backgroundText {
  stroke: $secondary_dark;
  stroke-width: 3px;
  fill: transparent;
  text-anchor: middle;
}

.container svg .foregroundText {
  fill: white;
  text-anchor: middle;
}

.container circle {
  fill: $secondary;
  stroke: $secondary_dark;
  stroke-width: 1.5px;
}

:global(.theme-dark) {
  .container svg .backgroundText {
    display: none;
  }

  .container svg .foregroundText {
    // fill: $dark-foreground;
    fill: $secondary;
  }

  .container circle {
    fill: $dark-elevated-11;
    stroke: $secondary;
    stroke-width: 1px;
  }
}
