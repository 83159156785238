@import "../../../../../../variables.scss";

.container {
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0px;
  overflow: hidden;
  flex-shrink: 0;
  background: whitesmoke;
  border-radius: 50%;
}

.container .empty {
  color: rgb(181, 181, 181);
  z-index: 500;
}

:global(.theme-dark) {
  .container {
    background: rgba(255, 255, 255, 0.1);
  }

  .container .empty {
    color: $grayDark;
  }
}
