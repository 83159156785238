@import "../../../../variables.scss";

.gray {
  color: $gray;
}

.backButton {
  margin-bottom: 12px;
  width: fit-content;
}

:global(.theme-dark) .gray {
  color: $grayDark;
}
