@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: row;
  padding: 0px;
  overflow: hidden;
}

.approvedIndicator {
  background-color: gray;
  width: 6px;
}

.outerContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
}

.innerContainer {
  display: flex;
  flex-direction: row;
  margin-top: 6px;
}

.parentVariantContainer {
  margin-right: 22px;
}

.parentSeperator {
  margin-right: 22px;
}

.vaiantTitleContainer {
  display: flex;
  flex-direction: row;
}

.actionsContainer {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
}

.actionsContainer button:not(:last-child) {
  margin-right: 2px;
}

.approveButtonsContainer {
  margin-right: 12px;
}

.variantId {
  flex-grow: 1;
  align-self: center;
}

.id {
  color: rgb(170, 170, 170);
  margin-right: 8px;
}

.outerContainer p {
  color: rgb(170, 170, 170);
  margin-top: 6px;
}

.name {
  color: black;
}

.rootName {
  color: black;
}

.gray {
  color: $gray;
}

.infoContainer {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}

.userCountContainer {
  display: flex;
  flex-direction: row;
}

.userCountContainer span:not(:last-child) {
  margin-right: 4px;
}

.languageContainer {
  display: flex;
  flex-direction: row;
  margin-left: 12px;
  cursor: pointer;
}

.languageContainer span:not(:last-child) {
  margin-right: 4px;
}

:global(.theme-dark) {
  .outerContainer p {
    color: rgba(255, 255, 255, 0.7);
  }

  .outerContainer,
  .name,
  .rootName {
    color: $dark-foreground;
  }

  .gray {
    color: $grayDark;
  }

  .id {
    color: rgba($color: #fff, $alpha: 0.3);
  }
}
