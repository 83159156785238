.cropContainer {
  align-self: center;
  display: flex;
  flex-direction: column;
}

.imageLoadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
}
