@import "../../../variables.scss";

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: $gray;
  font-weight: bold;
  font-size: 13px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid whitesmoke;
}

.separator:not(:empty)::before {
  margin-right: 0.5em;
}

.separator:not(:empty)::after {
  margin-left: 0.5em;
}
