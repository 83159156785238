@import "../../../../../variables.scss";

.profileBoxContainer {
  display: flex;
  flex-direction: row;
}

:global(.theme-dark) .profileBoxContainer {
  background-color: $dark-elevated-3;
}

.quizButtonContainer {
  align-self: center;
}

.shareButtonContainer {
  margin-left: 6px;
  align-self: center;
}

.profileContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.profileInnerContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  cursor: pointer;
  padding-right: 8px;
}

.profileInnerContainer:focus {
  outline: none;
}

.profileInfoContainer {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  justify-content: center;
}

.username {
  font-size: 20px;
  color: black;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

:global(.theme-dark) .username {
  color: $dark-foreground;
}
