@import "../../../variables.scss";

.container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 5000;
}

.feedbackButton:not(.newMessage) {
  color: $gray;
}

.feedbackButton:focus {
  border-color: $primary;
}

:global(.theme-dark) {
  .feedbackButton:not(.newMessage) {
    color: $grayDark;
  }

  .feedbackButton:not(.newMessage) :global(.icon) {
    color: $grayDark;
  }
}
