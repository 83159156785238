@import "../../../../../../../variables.scss";

.container {
  display: flex;
  padding-top: 8px;
  padding-bottom: 16px;
}

.innerContainer,
.dropContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.dropContainer.onDrop {
  outline: 6px solid $third;
  outline-offset: -3px;
}

.innerContainer:focus {
  outline: none;
}

.innerContainer.emptyImage {
  background-color: whitesmoke;
  min-height: 160px;
}

.innerContainer.emptyImage p {
  color: rgba(0, 0, 0, 0.7);
  align-self: center;
  margin-top: 8px;
}

.imageContainer {
  align-self: center;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.imageContainer .emptyImageIcon {
  color: $gray;
  z-index: 500;
  align-self: center;
}

.imageContainer .emptyImageIconClickable {
  cursor: pointer;
}

.imageContainer img {
  max-height: 450px;
  width: auto;
  background-color: whitesmoke;
}

.imageBackground {
  position: absolute;
  object-fit: cover;
  filter: blur(6px) brightness(80%);
  opacity: 0.3;
  margin-top: 5px;
  background-color: white;

  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.emptyImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

:global(.theme-dark) {
  .innerContainer.emptyImage {
    background: rgba(255, 255, 255, 0.1);
  }

  .imageContainer .emptyImageIcon {
    color: $grayDark;
  }

  .innerContainer.emptyImage p {
    color: rgba(255, 255, 255, 0.7);
  }

  .imageContainer img {
    background: rgba(255, 255, 255, 0.1);
  }
}
