@import "../../../variables.scss";

.hueContainer {
  position: relative;
  height: 10px;
  margin-top: 12px;
}

.saturationContainer {
  position: relative;
  height: 140px;
}

.previewContainer {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
}

.previewRectContainer {
  display: flex;
  position: relative;
  justify-content: center; // horizontal zentriert
  align-items: center;
}

.previewIcon {
  position: absolute;
}

.preview {
  height: 60px;
  width: 60px;
  border-radius: 4px;
}

.inputContainer {
  align-self: center;
}

.previewContainer input {
  border: none;
  font-size: 1.2em;
  color: #363636;
  line-height: 1.5;
  padding-bottom: 0.5em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: 0.5em;
}

.previewContainer input:focus {
  outline: none;
  border: none;
}

:global(.theme-dark) .previewContainer input {
  background-color: transparent;
  color: $dark-foreground;
}
