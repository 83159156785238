.container {
  line-height: 18px;
  font-size: 12px;
  color: white;
  text-align: center;

  a {
    text-decoration: underline;
    color: white;
  }
}
