@import "../../../variables.scss";

#SearchBarWrapper {
  align-self: center;
}

.searchBarInput {
  width: 180px;
  -webkit-transition: width 0.1s;
  -moz-transition: width 0.1s;
  -ms-transition: width 0.1s;
  -o-transition: width 0.1s;
  transition: width 0.1s;
}

:global(.theme-dark) .searchBarInput {
  color: #fff;
  background-color: $dark-elevated-2;
  border-color: transparent;
}

.searchBarInput:focus {
  border-color: $primary;
}

:global(.theme-dark) .searchBarInput:hover:not(:focus) {
  border-color: #777777;
}

:global(.theme-dark) .icon {
  color: rgba(255, 255, 255, 0.4) !important;
}

:global(.theme-dark) .searchBarInput:focus ~ .icon {
  color: rgba(255, 255, 255, 0.7) !important;
}

@media screen and (max-width: 1023px) {
  .searchBarInput {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .searchBarInputExpanded {
    width: 450px;
  }
}

#SearchBarResultsWrapper {
  position: absolute;
  top: 46px;
  max-height: 300px;
  margin-left: 1px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1000;
}

@media screen and (max-width: 768px) {
  #SearchBarResultsWrapper {
    max-height: 200px;
  }
}

#SearchBarResultsWrapper:focus {
  outline: none;
}

.searchResultsBox {
  background-color: #fff;
  min-width: 448px;
  max-width: 4488px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

:global(.theme-dark) .searchResultsBox {
  background-color: $dark-elevated-6;
}

@media screen and (max-width: 1023px) {
  .searchResultsBox {
    min-width: -webkit-calc(100% - 26px);
    min-width: -moz-calc(100% - 26px);
    min-width: calc(100% - 26px);
    max-width: -webkit-calc(100% - 26px);
    max-width: -moz-calc(100% - 26px);
    max-width: calc(100% - 26px);
  }
}

.searchResultsShadow {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

#SearchResultsWrapper {
  padding: 9px;
}

#cancelSearch {
  right: 8px;
  pointer-events: initial;
  cursor: pointer;
}

#cancelSearch:focus {
  outline: none;
}
