.container {
  width: 100%;
  align-self: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
}

.containerSide {
  align-self: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.textTitleContainer {
  padding-left: 12px;
  padding-right: 12px;
  align-self: center;
}

.title {
  margin-bottom: 12px;
}

.image {
  width: 160px;
  flex-shrink: 0;
  align-self: center;
}

.divider {
  width: 100%;
  align-self: center;
}

@media only screen and (max-width: 1408px) {
  .containerSide {
    flex-direction: column;
  }

  .image {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 1409px) {
  .image {
    margin-right: 24px;
    margin-left: 12px;
  }
}
