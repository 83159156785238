@import "../../../../variables.scss";

.container {
  margin-bottom: 100px;
}

.filters {
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
}

.checkboxContainer {
  margin-top: 10px;
  align-self: flex-start;
}
