.container {
  display: flex;
}

.container.disabled {
  opacity: 0.25;
}

.text {
  flex-grow: 1;
}

.input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.copyButton {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
