@import "../../../../../../../variables.scss";

.cardEditor {
  margin-top: 12px;
}

.checkbox {
  font-size: 12px;
  color: $gray;
}

.checkbox:hover {
  color: $gray;
}
