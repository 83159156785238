#container {
  width: auto;
  height: wrap content;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.iconGray {
  color: rgb(181, 181, 181);
}

#addContentButton {
  align-self: center;
}

.addBlockButtonContainer {
  display: flex;
  margin-top: 24px;
  justify-content: center;
}

.spinner {
  margin-top: 24px;
  margin-bottom: 24px;
}

.hasMoreContainer {
  margin-top: 32px;
  margin-bottom: 32px;
}
