.modal {
  z-index: 4000;
  opacity: 1;
  animation: fadein 0.15s ease;
  -webkit-animation: fadeIn 0.15s ease;
  -moz-animation: fadeIn 0.15s ease;
  -o-animation: fadeIn 0.15s ease;
  -ms-animation: fadein 0.15s ease;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
