@import "../../../variables.scss";

.container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding-top: 12px;
  padding-bottom: 12px;
}

.innerContainer {
  padding-right: 16px;
  display: flex;
  flex-direction: row;
}

.colorsContainer {
  display: flex;
  flex-direction: row;
}

.swatchContainer:not(:last-child) {
  margin-right: 2px;
}

.currentColorContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.currentColorContainer p {
  font-weight: bold;
  color: $gray;
  font-size: 1rem;
  min-width: 80px;
}

:global(.tag).plusButton {
  font-weight: bold;
  color: $gray !important;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  align-self: center;
  font-size: 1rem !important;

  margin-left: 8px;

  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  -moz-transition: opacity 0.2s, visibility 0.2s;
  -ms-transition: opacity 0.2s, visibility 0.2s;
  -o-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}

:global(.tag).plusButton:hover {
  background-color: rgb(240, 240, 240) !important;
}

:global(.tag).plusButton:active {
  background-color: rgb(235, 235, 235) !important;
}

:global(.tag).plusButton:focus {
  outline: none;
  background-color: rgb(235, 235, 235) !important;
}

:global(.tag).plusButton.hidden {
  opacity: 0;
  visibility: hidden;
}

.newLoadingContainer {
  align-self: center;
  margin-left: 8px;
}

:global(.theme-dark) {
  .plusButton {
    background-color: $dark-elevated-9 !important;
  }

  .plusButton:hover {
    background-color: $dark-elevated-11 !important;
  }

  .plusButton:active {
    background-color: $dark-elevated-11 !important;
  }
}
