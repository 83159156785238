@import "../../../../../variables.scss";

.User {
  width: auto;
  height: 60px;
  padding: 0px;
  margin-bottom: 8px !important;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.userInfo {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  flex-grow: 1;
}

.userName {
  font-size: 16px;
}

.secondaryInfo {
  font-size: 12px;
}

.userLevel {
  border: transparent solid 1px;
  border-radius: 6px;
  background-color: $secondary;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 6px;
}

.jobToBeDoneIcon {
  margin-left: -4px;
  color: $grayDark;
}

.userEmail {
  // margin-left: 4px;
}

.userImage {
  width: 60px;
  height: 60px;
}

.userEmptyImage {
  background: whitesmoke;
  color: rgb(181, 181, 181);
}

.userDetailsContainer {
  margin-right: 20px;
  font-size: 12px;
}

.userGrowthStats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.growthIcon {
  color: $primary;
}

:global(.theme-dark) {
  .userEmptyImage {
    background-color: $dark-elevated-9;
    color: $grayDark;
  }

  .userLevel {
    border: $secondary solid 1px;
    border-radius: 6px;
    background-color: transparent;
    color: $dark-foreground;
  }
}
